import { IoIosPricetags } from "react-icons/io";
import { BiRupee } from "react-icons/bi";
import useCouponSelect from "../../../Pages/Payment/PaymentComponent/CouponSelect";

const OrderSummary = ({
	billingDetails,
	profileData,
	checkout,
	setShow,
	couponDetails,
}) => {
	const {
		amount,
		totalPrice,
		couponCode,
		discount,
		tax,
		validCoupon,
		couponDiscount,
		taxes,
	} = useCouponSelect(couponDetails, profileData, setShow, checkout);
	const appliedCouponData = JSON.parse(localStorage.getItem('couponData'));
	const subscriptionData = JSON.parse(localStorage.getItem('subscriptionDetails'));
	const BillingAddress = JSON.parse(localStorage.getItem("userAddress"));
	console.log(appliedCouponData, subscriptionData, "appliedCouponData");
	const stateList = JSON.parse(localStorage.getItem("stateList"));
	const stateName = stateList.filter(state => state.stateCode == BillingAddress?.state)[0];
	console.log(stateName);
	return (
		<>
			<section className='billing-summary-container'>
				<h4 className='fs-6'>Billing Address</h4>
				<address className='address-container flex-grow-1'>
					<span className='address-line'>
						Address:
						{/* <b>{profileData?.address || ""}</b> */}
						<b>{BillingAddress?.address || ""}</b>
					</span>
					<span className='address-line'>
						District: <b>{BillingAddress?.city || ""}</b>
					</span>
					<span className='address-line'>
						State: <b>{stateName?.stateName || ""}</b>
					</span>
					<span className='address-line'>
						Pincode: <b>{BillingAddress?.pincode || ""}</b>
					</span>
				</address>
			</section>
			<section className='border' style={{ borderRadius: "8px" }}>
				<div>
					<h4 className='summary-heading m-0 pt-3 pl-3'>
						<IoIosPricetags /> Payment Summary{" "}
					</h4>
					<hr className='my-3' />
				</div>
				<div className='address-container for-m-0 mx-4'>
					<div className='address-line '>
						<p>Amount</p>
						<strong>
							<BiRupee /> {amount}
						</strong>
					</div>
					<div className='address-line '>
						<p>Discount</p>
						<strong>
							<BiRupee /> {discount}
						</strong>
					</div>
					{validCoupon && (
						<div className='address-line '>
							<p>Coupon Applied</p>
							<strong>{couponCode}</strong>
						</div>
					)}
					<div className='address-line '>
						<p>Coupon Discount {appliedCouponData?.couponDiscountAmt}</p>
						{appliedCouponData?.couponDiscountAmt == null ? (
							<strong>
								<BiRupee /> 0
							</strong>
						) : (
							<strong>
								- <BiRupee /> {appliedCouponData ? appliedCouponData?.couponDiscountAmt : "0.00"}
							</strong>
						)}
					</div>
					{/* TODO: Place the condition wheater it igst or cgst/sgst */}
					{appliedCouponData?.taxes == "" || appliedCouponData?.taxes != 0 || appliedCouponData?.taxes != null ? (
						<>
							<div className='address-line '>
								<p>Taxes</p>
								<strong>
									<BiRupee /> {subscriptionData?.taxes}
								</strong>
							</div>
						</>
					) : (
						<>
							<div className='address-line '>
								<p>Taxes</p>
								<strong>
									<BiRupee /> {appliedCouponData ? appliedCouponData?.taxes : subscriptionData?.taxes}
									{/* FIXME: SGST tax Change*/}
								</strong>
							</div>
						</>
					)}
				</div>
				<hr />
				<div
					style={{ borderRadius: "8px 8px 0 0" }}
					className='address-line text-success mx-4'
				>
					<p className='m-0'>After Discount Price</p>
					<strong>
						<BiRupee /> {appliedCouponData != null || appliedCouponData ? appliedCouponData?.totalPayableAmt : subscriptionData?.totalPayableAmt}
					</strong>
				</div>

				<div
					style={{ borderRadius: "0 0 8px 8px" }}
					className='address-line mx-4 mb-4'
				>
					<p className='m-0'>Total Amount</p>
					<strong>
						<BiRupee />  {appliedCouponData != null || appliedCouponData ? appliedCouponData?.totalPayableAmt : subscriptionData?.totalPayableAmt}
					</strong>
				</div>
			</section>
		</>
	);
};

export default OrderSummary;
