import Header from "../Components/Global/Navigation/Header";
import LandingPageFooter from "../Components/Global/Footers/LandingPageFooter";

export default function Termncondition() {
  return (
    <div className="">
      <div className="container py-5 cst_container">
        <form className="instruct shadow-lg mt-5 text-justify">
          <h6 className="orange-btn text-center" type="button">
            TERMS & CONDITIONS
          </h6>
          <p>
            These Terms and Conditions(hereinafter referred to as <b>“Terms”</b>
            ) of use our website i.e. Besst.in(hereinafter referred to as
            <b> “Website”</b>), Mobile Application (hereinafter referred to as{" "}
            <b>“App”</b>), Desktop Application and/or any products or services
            in connection thereto are between{" "}
            <b>BRAHMAPUTRA EXAM SUCCESS SUPPORT TEAM PRIVATE LIMITED</b>
            (hereinafter referred to as “BESST”) and its users(hereinafter
            referred to as <b>"User/You”</b>). Please read these Terms and
            Conditions carefully before accessing or using the Product and
            Services provided by BESST. By accessing or using Product and
            Services provided by BESST, the user agrees to be bound by these
            Terms and Conditions. User may not access Product and Services if
            they don’t agree to all the Terms and Conditions incorporated
            herein.
          </p>
          <p>
            {" "}
            Any new product or services provided by BESST, or any new features
            or tools which are added unless specifically stated otherwise, be
            subject to these Terms and Conditions. User must keep up to date
            with Terms and Conditions as BESST reserves the right to update,
            change or replace any part of these Terms and Conditions by updates
            and/or changes to “BESST” application, website or platform. BESST
            may without prior notice, change the Services or the BESST Products;
            add or remove functionalities or features; stop providing the
            Services or the BESST Products, or features of the Services or the
            BESST Products, to the Users (specifically and generally)
          </p>
          <h6> 1. Privacy Policy</h6>
          <p>
            These Terms and conditions of Service refer to, incorporate, and
            include the privacy policy of BESST which sets out the terms on
            which BESST collects & processes any of the User’s personal data.
            User consents to such processing and the User warrants that all data
            provided by the User is accurate. The latest version of the Privacy
            Policy can be accessed at the website of BESST.
          </p>
          <h6> 2. Definitions</h6>
          <ul>
            <li>
              {" "}
              <b>“Additional Terms”</b> means the terms and conditions
              applicable for use of the Apple App Store/Google Play Store and/or
              any other platform where the Mobile Application or Desktop
              Application is made available for download;
            </li>
            <li>
              {" "}
              “Content” shall mean any content published, uploaded, shared,
              distributed or transmitted by the User on BESST products including
              the products listed on the website by the User and their
              descriptions, prices, and any other content uploaded;
            </li>
            <li>
              {" "}
              “Desktop Application” means the computer application developed by
              BESST for availing the Services;
            </li>
            <li>
              {" "}
              “Fees” means the fee payable for availing of the Services, as
              specified by BESST.
            </li>
            <li>
              {" "}
              “Force Majeure Event” means any event or circumstance, regardless
              of whether it was foreseeable (including without limitation an act
              of war, hostility, or sabotage; act of God; pandemic, epidemic
              electrical, internet or telecommunication outage that is not
              caused by the obligated party; government restrictions (including
              the denial or cancellation of any export or other license);
              criminal, revolutionary, or terrorist activity), that: (a) was not
              caused by a party and, (b) prevents that party from complying with
              any of its obligations pursuant to these Terms(other than an
              obligation to pay money) or provision of the Services.
            </li>
            <li>
              {" "}
              “BESST Products” means, collectively, the Mobile Application,
              Desktop Application, Website, and any other products provided by
              BESST to the Users from time to time;
            </li>
            <li>
              {" "}
              “Intellectual Property Rights” means patents, trademarks, rights
              in designs, copyright, database rights, semiconductor topography
              rights, utility models, formulae, processes, trade secrets,
              proprietary information, rights in knowhow and other intellectual
              property rights including all the ancillary rights arising from
              the mention rights, in each case, whether registered or not and
              including applications for registration, and all rights or forms
              of protection having equivalent or similar effect anywhere in the
              world;
            </li>
            <li>
              {" "}
              “Mobile Application” means the mobile application developed by
              BESST for availing the Services;
            </li>
            <li>
              {" "}
              “Person” shall mean any natural person, limited or unlimited
              liability company, corporation, partnership (whether limited or
              unlimited), proprietorship, Hindu undivided family, trust, union,
              association, government or any agency or political subdivision
              thereof or any other entity that may be treated as a person under
              applicable law;
            </li>
            <li>
              {" "}
              “Services” means the services including online/offline provided by
              BESST to an User through the Mobile Application, Desktop
              Application, Website and any other BESST Products from time to
              time;
            </li>
            <li>
              {" "}
              “Website” means the website or web links provided by BESST for
              availing the Services.
            </li>
          </ul>
          <h6> 3. Conditions for use</h6>
          <ul>
            <li>
              {" "}
              The Terms and Conditions may be entered into by any natural person
              who has attained the age of majority i.e. 18 years and is
              competent to contract or an authorized non-natural person
              permitted under Applicable Laws to accept these Terms and
              Conditions.
            </li>
            <li>
              {" "}
              In case BESST alters or amends the eligibility criteria to be
              registered for receiving the Services and the User no longer
              complies with the new eligibility criteria, as determined by BESST
              in its sole discretion, the User accepts that BESST may restrict
              or suspend the access.
            </li>
            <li>
              {" "}
              The BESST Products will be subject to the Additional Terms as
              applicable. In case of conflict between two terms instant terms of
              service prevails.
            </li>
            <li>
              {" "}
              The User shall co-operate with BESST and its officers,
              representatives, directors and agents and render assistance to the
              BESST in integration of the BESST Products, if so required by
              BESST, for the purpose of enabling and providing the Services.
            </li>
            <li>
              {" "}
              The User shall be responsible for the proper security of its
              devices used to access the BESST Products to prevent any hacking
              and the User accepts all liabilities with respect processors for
              the collection of the Fees and the User shall strictly adhere to
              the terms of such authorized affiliates, banks, financial
              institutions and payment processors with respect to the payment of
              Fees. to any compromise or unauthorized use or disclosure or
              access to such information.
            </li>
          </ul>
          <h6> Availability of the Services and the BESST Products</h6>
          <ul>
            <li>
              {" "}
              The User agrees and understands that certain features and services
              on the BESST Products are paid only and can be accessed on payment
              of Fee.
            </li>
            <li>
              {" "}
              In case of the non-payment of the Fees, BESST reserves the right
              to discontinue the paid Services and/or restrict the User’s access
              to the paid features and services on BESST Products.
            </li>
            <li>
              {" "}
              The Fees for the Services or any other services provided by BESST,
              as displayed on the BESST Products, are subject to change. BESST
              reserves the right to modify the Fee at any time with a
              notification to the User. For any change in mode of communication
              at the end of the user, he shall be liable for intimating the same
              to BESST. In case of the User’s continued use of the BESST
              Products, it shall be deemed that the User has accepted such
              revised Fees.
            </li>
            <li>
              {" "}
              The User agrees to promptly update the User’s account and other
              information required by BESST, including the User’s email address,
              mobile number, personal details, and details of a User’s payment
              instrument, in connection with the provision of the Services. The
              User undertakes to provide all such information, documents, and
              data as may be required or instructed by BESST for the use of the
              Services.
            </li>
            <li>
              {" "}
              The User will be required to setup an account with BESST for the
              BESST Products for the use of the Services. The User will be
              required to provide personal details, login information, a
              password and other information required by BESST in connection
              with the setup of the account. In case of any inaccuracy or
              material omission in the information provided by the User to
              BESST, BESST reserves the right to discontinue provision of the
              Services or prevent the User’s right to access the BESST Products.
            </li>
            <li>
              {" "}
              The User hereby grants BESST the right to access all information,
              data and details stored or available on the Software, including,
              without limitation, or to any Confidential Information, that is
              required or necessary for BESST for the provision of the Services.
              The User agrees and accepts that BESST may store, transfer,
              process and utilize the information, data and details of the User,
              including, without limitation, any Confidential Information, in
              connection with the provision of Services, including without
              limitation to, store such information, data and details in the
              servers of BESST. Independent of the User's registration under the
              applicable Do Not Disturb (DND), Do Not Call (DNC) or the National
              Do Not Call (NCPR) databases, the User agrees and accepts that
              BESST or any of its affiliates may contact the User, through
              either email or phone call or SMS, and offer the Services,
              including but not limited to the dissemination of information
              about BESST Products, and promotional offers by BESST, its
              affiliates or associated third parties.
            </li>
          </ul>
          <h6> Restrictions on the use of the BESST Products</h6>
          <ul>
            <li>
              {" "}
              The User shall not use the BESST Products or the Services in any
              manner inconsistent with these Terms of the Software Providers, or
              in breach of Applicable Laws.
            </li>
            <li>
              {" "}
              The User shall not redistribute, sublicense, rent, publish, sell,
              assign, lease, market, transfer, or otherwise make the BESST
              Products or any component or content thereof, available to any
              third parties prior written consent of BESST.
            </li>
            <li>
              {" "}
              The User shall not, directly or indirectly, attempt to exceed the
              scope of authorized access to the BESST Products, account
              information or other areas of the BESST Products or solicit
              passwords, transfer any part of their account to any third party.
            </li>
            <li>
              {" "}
              The User shall not circumvent or disable any digital rights
              management, usage rules, or other security features of BESST
              Products; remove, alter, or obscure any proprietary notices
              (including copyright notices) on any portion of the BESST
              Products; and not use the BESST Products in a manner that
              threatens the integrity, performance, or availability of the BESST
              Products.
            </li>
            <li>
              {" "}
              Without limiting the foregoing, the User agrees that the it shall
              not use the BESST Products, or the Services to undertake any of
              the following actions or to display, upload, modify, publish,
              distribute, disseminate, transmit, update or share any information
              that:
            </li>
          </ul>
          <ul>
            <li>
              {" "}
              is grossly harmful, harassing, defamatory, obscene, pornographic,
              paedophilic, libellous, invasive of another's privacy, hateful, or
              racially, ethnically objectionable, disparaging, relating or
              encouraging money laundering or gambling, or otherwise unlawful in
              any manner whatsoever;
            </li>
            <li>
              {" "}
              infringes any patent, trademark, copyright or other proprietary
              rights;
            </li>
            <li>
              {" "}
              contains viruses, corrupted files, or any other similar software
              or programs that is designed to interrupt, destroy or that may
              limit the functionality of any computer source or that may damage
              or adversely affect the operation of another person's computer,
              its web-sites, any software or hardware, or telecommunications
              equipment;
            </li>
            <li>
              {" "}
              is in the nature of promotional services, products, surveys,
              contests, pyramid schemes, spam, unsolicited advertising or
              promotional materials, or chain letters;
            </li>
            <li>
              {" "}
              falsifies or deletes any author attributions, legal or other
              proper notices or proprietary designations or labels of the origin
              or the source of software or other material;
            </li>
            <li>
              {" "}
              interferes with or disrupts the BESST Products or other BESST
              websites, servers, or networks;
            </li>
            <li> impersonate any other person;</li>
            <li>
              {" "}
              forges headers or manipulates identifiers or other data in order
              to disguise the origin of any content transmitted through its
              websites or to manipulate the User’s presence on its websites;
            </li>
            <li>
              {" "}
              defame BESST, the Software Providers, and their employees,
              officers, directors, agents, partners and service providers,
              including without limitation, by posting any defamatory content on
              any social media;
            </li>
            <li>
              {" "}
              threatens the unity, integrity, defence, security or sovereignty
              of India, friendly relations with foreign states, or public order,
              or causes incitement to the commission of any cognizable offence,
              or prevents the investigation of any offence or insults any other
              nation.
            </li>
          </ul>
          <h6>
            {" "}
            f. The User shall not attempt to or engage in any activity that may:
          </h6>
          <ul>
            {" "}
            <li>
              {" "}
              reverse engineer, decompile or otherwise extract the source code
              related to the BESST Products or the Software or any part thereof,
              unless it is expressly permitted by BESST to the User in writing
              or is required by the Applicable Law;
            </li>
            <li>
              {" "}
              use any robot, spider, retrieval platform, or other device to
              retrieve or index any portion of the BESST Products, Software or
              content thereof;
            </li>
            <li>
              {" "}
              collect information about other Users for any illegal or unlawful
              purpose;
            </li>
            <li>
              {" "}
              create any User accounts by automated means or under false or
              fraudulent pretenses for using the BESST Products;
            </li>
            <li>
              {" "}
              transmit any viruses, worms, defects, trojan horses, or any items
              of a destructive nature through the BESST Products;
            </li>
            <li>
              {" "}
              use the BESST Products in any manner that could damage, disable,
              overburden, or impair, or undertake any action which is harmful or
              potentially harmful to, any of the servers, networks, computer
              systems or resources connected to any of the servers connected,
              directly or indirectly to the BESST Products, or interfere with
              any other third party Person's use and enjoyment of the BESST
              Products;
            </li>
          </ul>
          <p>
            {" "}
            g. The User agrees and accepts that the use of BESST Products is
            subject to the Acceptable Use Policy, which is incorporated into
            these Terms by reference. The Acceptable Use Policy may be updated
            from time to time and it is the responsibility of the User to keep
            updated about any changes. Any restrictions under the Acceptable Use
            Policy shall be in addition to and not in derogation with these
            Terms. The Acceptable Use Policy is available at the website of
            BESST.
          </p>
          <h6> 6. BESST’s rights</h6>
          <h6>
            {" "}
            a. BESST reserves the right to suspend/discontinue the BESST
            Products, Services and any other products/services to the User
            and/or to sever its relationship with the User, at any time, for any
            cause, including, but not limited, to the following:
          </h6>
          <ul>
            <li>
              {" "}
              For any suspected violation of the rules, regulations, orders,
              directions, notifications, laws, statutes, from time to time or
              for any violation of the terms and conditions mentioned in these
              Terms.
            </li>
            <li>
              {" "}
              For any suspected discrepancy in the particular(s), documentation
              or information provided by the User;
            </li>
            <li>
              {" "}
              To combat potential fraud, sabotage, willful destruction, threat
              to national security, for any other force majeure reasons etc.;
            </li>
            <li>
              {" "}
              If the same is due to technical failure, modification, up
              gradation, variation, relocation, repair, and/or maintenance due
              to any emergency or for any technical reasons;
            </li>
            <li>
              {" "}
              If the same is due to any transmission deficiencies caused by
              topographical and geographical constraints/limitations;
            </li>
            <li>
              {" "}
              If the User does not have the control over the User’s login
              details and password or the User’s account is compromised in any
              other manner;
            </li>
            <li>
              {" "}
              If the User indulges in any abusive or aggressive or threatening
              behavior or any act or any intimidation or harassment of any kind
              (be it verbal or physical or written) against any
              employee/representative/agent of BESST or any other User; and
            </li>
            <li>
              {" "}
              If BESST believes, in its reasonable opinion, that cessation/
              suspension is necessary.
            </li>
          </ul>
          <h6> 7. Accuracy of information</h6>
          <ul>
            <li>
              {" "}
              BESST is not responsible if information made available on the
              BESST Products (including any information in relation to the
              Services) is not accurate, complete or current. The material on
              the BESST Product is provided for general information only and
              should not be relied upon or used as the sole basis for making
              decisions without consulting, primary, more accurate, more
              complete or more timely sources of information. Any reliance on
              the material on this BESST Product (including any information in
              relation to the Products) is at the User’s own risk.
            </li>
            <li>
              {" "}
              The BESST Products may contain certain historical information.
              Historical information, necessarily, is not current and is
              provided for the User’s reference only. BESST reserves the right
              to modify the contents of the BESST Product at any time, but BESST
              has no obligation to update any information on the BESST Products.
              The User agrees that it is the User’s responsibility to monitor
              changes to the BESST Products or any other information provided in
              connection with the Services.
            </li>
            <li>
              {" "}
              BESST undertakes no obligation to update, amend or clarify
              information on the BESST Products or on any related website, or in
              relation to the Services, including without limitation, pricing
              information, except as required by Applicable Laws. No specified
              update or refresh date applied in the BESST Product or on any
              related website, should be taken to indicate that all information
              in the BESST or on any related website has been modified or
              updated.
            </li>
          </ul>
          <h6> Warranties</h6>{" "}
          <p>
            {" "}
            No warranty of any kind, implied, express or statutory, including
            but not limited to the warranties of non-infringement of third party
            rights, title, merchant ability, satisfactory quality or fitness for
            a particular purpose, is given in conjunction with BESST Product or
            any data/content, including without limitation, the services. While
            BESST may apply such technology as it deems fit to ensure the
            security of and prevent unauthorized access to its
            products/services, BESST does not warrant that products/services or
            any content/data will be provided uninterrupted or free from errors
            or that any identified defect will be immediately corrected.
            Further, no warranty is given that products/services or any
            data/content are free from any computer virus or other malicious,
            destructive or corrupting code. ALL OTHER WARRANTIES, WHETHER LEGAL,
            EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO ANY EXPRESS OR
            IMPLIED WARRANTIES OF MERCHANTABILITY, OF FITNESS FOR A PARTICULAR
            USAGE OR FOR THE INTENDED USE THEREOF ARE EXPRESSLY EXCLUDED IN
            CONNECTION WITH THE USE OF THE BESST PRODUCTS OR SERVICES.
          </p>
          <h6> 9. Exclusions of liability</h6>
          <p>
            {" "}
            a. Notwithstanding anything to the contrary BESST, its successors,
            agents, assignors, and each of their directors, officers, employees,
            associates, agents, and representatives shall in no event be liable
            to the User or any other Person for:
          </p>
          <ul>
            <li>
              {" "}
              any indirect, incidental, special, consequential, punitive or
              economic loss, expense or damage arising from or in connection
              with any access, use or the inability to access or use BESST
              Products, the Services or any other products/services of BESST and
              data/content or reliance on those, including without limitation,
              in case of the Services, the BESST Products, howsoever caused and
              regardless of the form of action (including tort or strict
              liability);
            </li>
            <li>
              {" "}
              any downtime costs, loss of revenue or business opportunities,
              loss of profit, loss of anticipated savings or business, loss of
              data, loss of goodwill or loss of value of any equipment including
              software; and/or
            </li>
            <li>
              {" "}
              any loss or damage arising as a result of a disclosure/sharing or
              hacking of the User’s login details and password, and any improper
              usage, failure or malfunction of any computer or mobile phone,
              unavailability of an electricity connection, other
              telecommunications equipment, any other services or equipment
              necessary to access BESST products and services, including without
              limitation, the Services, the BESST Products.
            </li>
            <li>
              {" "}
              act or omission of any third party including but not limited to
              any equipment or software providers, any service providers, any
              network providers (including but not limited to telecommunications
              providers, internet browser providers and internet access
              providers), or any agent or subcontractor of any of the foregoing;
            </li>
            <li>
              {" "}
              the use of BESST products and services, including without
              limitation, the Services, the BESST Products by third Persons,
              whether authorized or unauthorized by the User;
            </li>
            <li>
              {" "}
              theft or loss of the User’s computer, mobile phone, the User’s
              data, the User’s login details and password, hardware and/or
              equipment on which the BESST Products are accessed or operated;
            </li>
            <li>
              {" "}
              the User being deprived of the use of the BESST Product as a
              consequence of any act or omission by BESST for any reason
              including but not limited to compliance with any applicable laws
              and/or regulations and any instructions and/or directions given by
              any local or foreign regulatory body, government agency, statutory
              board, ministry, departments or other government bodies and/or its
              officials.
            </li>
          </ul>
          <p>
            {" "}
            NOTWITHSTANDING ANYTHING TO THE CONTRARY, IN NO EVENT, SHALL BESST
            OR ANY OF ITS DIRECTORS, EMPLOYEES, AGENTS AND/OR PERSONNEL, BE
            LIABLE TO THE USER OR ANY PERSON FOR ANY DAMAGES, LIABILITIES,
            LOSSES, AND CAUSES OF ACTION ARISING OUT OF OR RELATING TO: (I)
            THESE TERMS, THE SERVICES, THE BESST PRODUCTS, OR ANY REFERENCE
            SITE, APP, PRODUCTS OR SERVICES; OR (II) THE USER’S USE OR INABILITY
            TO USE THE SERVICES, THE BESST PRODUCTS, OR OTHER PRODUCTS OR
            SERVICES OF BESST OR ANY REFERENCE SITE; OR (III) ANY OTHER
            INTERACTIONS WITH BESST; HOWEVER CAUSED AND WHETHER ARISING IN
            CONTRACT, TORT OR IN EQUITY INCLUDING NEGLIGENCE, WARRANTY OR
            OTHERWISE, BEYOND OR IN EXCESS OF INR 1,000/- OR ANY AMOUNT AS MAY
            BE PERMITTED UNDER APPLICABLE LAWS, WHICHEVER IS LOWER:
          </p>
          <ul>
            <li>
              {" "}
              BESST shall make all best efforts to provide uninterrupted
              services subject to down time and regular maintenance.
              Notwithstanding anything in these Terms, the User acknowledges
              that the BESST Products and the Services may not be uninterrupted
              or error free or free from any virus or other malicious,
              destructive or corrupting code, program or macro. BESST shall
              adopt all such best technical and non-technical security measures
              that it considers are appropriate and are the same as that is
              prevalent in the industry to render the Services, however BESST
              does not guarantee that such security measures cannot be subverted
              to gain unauthorized access. In the event of interruption to the
              Services or loss of use and/or access to the BESST Products, BESST
              shall be to use best endeavors to restore the Services and/or
              access to the BESST Products as soon as reasonably possible.
            </li>
            <li>
              {" "}
              BESST is not responsible for any Content uploaded by the User on
              the BESST Products. BESST is not responsible for any direct or
              indirect damages or losses caused to the User, including without
              limitation, lost profits, business interruption or other loss
              resulting from use of or reliance in any way on anything available
              on the BESST Products. It is solely the User’s responsibility to
              evaluate the accuracy, reliability, completeness and usefulness of
              information and content available on the BESST Products that is
              used by the User.
            </li>
          </ul>
          <h6> 10. Third-party services</h6>
          <ul>
            <li>
              BESST may provide the User with access to third-party tools and
              services and certain content, products and services available by
              BESST may include materials from third-parties which BESST neither
              monitor nor have any control over. Third-party links on the BESST
              Product may direct the User to third-party websites that are not
              affiliated with BESST.
            </li>
            <li>
              The User acknowledges and agrees that BESST provides access to
              such links, website, tools and services “as is” and “as available”
              without any warranties, representations or conditions of any kind
              and without any endorsement and BESST does not warrant and will
              not have any liability or responsibility for any third-party
              materials or websites, or for any materials, products, or services
              of third-parties. BESST shall have no liability whatsoever arising
              from or relating to the User’s use of optional third-party tools
              and services. The User is requested to review the third-party’s
              policies and practices carefully and make sure that the User
              understands them. Complaints, concerns, or questions regarding
              third-party products should be directed to the third-party.
            </li>
          </ul>
          <h6> Viruses</h6>
          <ul>
            <li>
              The User will be responsible for introduction of any viruses,
              trojans, worms, logic bombs or other material which is malicious
              or technologically harmful. The User shall not attempt to gain
              unauthorized access to the BESST Products, the server on which the
              BESST Products is stored or any server, computer or database
              connected to the BESST Products. By breaching this provision, the
              User would commit a criminal offence under the Information
              Technology Act, 2000. BESST will report any such breach to the
              relevant law enforcement authorities and the BESST will cooperate
              with such Governmental Authorities by disclosing the User’s
              identity to them. In the event of such a breach, the User’s right
              to use the BESST Products and the Services will cease immediately.
            </li>
            <li>
              BESST will not be liable for any loss or damage caused by a virus,
              denial-of-service attack, distributed denial-of-service attack, or
              other technologically harmful material that may infect the User’s
              computer equipment, computer programs, data or other proprietary
              material due to the User’s use of the BESST Products or the User’s
              downloading of any information and contention it, or on any
              website linked to it.
            </li>
          </ul>
          <h6> 12. Refunds</h6>
          <p>
            {" "}
            If any User is unable to avail the services/products provided by the
            BESST due to any technical fault on our part, the User will be
            entitled for refund of fees paid, at the rate decided by the BESST.
            Decision of the BESST with regard to such refunds will be final and
            binding upon the parties.
          </p>
          <h6>13. User Referrals</h6>
          <p>
            {" "}
            Referrals made by existing Users of BESST Products to invite new
            users to register and purchase subscription of BESST Products shall
            be governed by BESST Referral Policy available at the website of
            BESST.
          </p>
          <h6> 14. Intellectual property</h6>
          <ul>
            <li>
              {" "}
              All Intellectual Property Rights in the BESST Products or the
              Services belong to BESST or the applicable third-party owner of
              such rights, including without limitation, the Software Providers.
              Nothing in these Terms shall operate to transfer any Intellectual
              Property Rights beyond the specific licenses, as may be
              specifically agreed in writing. BESST retains ownership of all
              right, title to, and interest in any and all Intellectual Property
              Rights developed, owned, licensed or created by the BESST.
            </li>
            <li>
              {" "}
              No part or parts of the BESST Products may be reproduced,
              distributed, republished, displayed, broadcast, hyperlinked,
              transmitted, adapted, modified to create derivative works or
              otherwise commercially exploited in any manner or by any means or
              stored in an information retrieval system without BESST prior
              written permission. The User may view, print or use BESST’s
              content for personal, non-commercial use only, provided further
              that the User does not modify the content and that the User
              retains all copyright notices and other proprietary notices
              contained in the content.
            </li>
            <li>
              {" "}
              The User shall not claim any rights or interest in the
              Intellectual Property Rights of BESST, or in connection with any
              other services, features or product offered by BESST to the User,
              including without limitation, the BESST Products or the Services.
              In no event shall the User alter, tamper, vary, modify, change,
              disassemble, decompile, or reverse engineer any Intellectual
              Property Rights of BESST (including without limitation any
              Intellectual Property Rights licensed to the Company by any other
              Person) (collectively, “IP Property”), or permit others to do so.
              Altering, tampering, varying, modifying, changing, disassembling,
              decompiling and reverse engineering include, without limitation:
            </li>
            <h6> 15. Content</h6>
          </ul>
          <ul>
            <li>
              {" "}
              BESST does not claim any Intellectual Property Rights over the
              Content provided by the user on the website. By uploading Content,
              user agree: (a) to allow other internet users to view the Content
              you post publicly on the website; (b) to allow BESST to store, and
              in the case of Content you post publicly, display and use your
              Content; and (c) that BESST can, at any time, review and delete
              all the Content submitted through a BESST Product, although BESST
              is not obligated to do so.
            </li>
            <li>
              {" "}
              Notwithstanding anything contained in clause 17 (Intellectual
              Property), BESST shall have the non-exclusive, worldwide and
              royalty free license to use the names, images trademarks, service
              marks and logos associated with your Store to promote its
              Services. You grant BESST a non-exclusive, transferable, sub-
              licensable, royalty-free, worldwide licence to host, use,
              distribute, modify, run, copy, publicly perform or display,
              translate and create derivative works of any Content provided by
              the user.
            </li>
          </ul>
          <h6>16. Indemnification</h6>
          <p>
            The User shall indemnify, defend and hold harmless the Indemnified
            Parties (whether or not arising out of third-party claims) BESST,
            the Software Providers and their respective parent, subsidiaries,
            affiliates, partners, officers, directors, agents, contractors,
            licensors, service providers, subcontractors, suppliers, interns and
            employees from all claims, demands, losses, costs, fees suffered or
            incurred by the Indemnified Parties due to or arising out of a) any
            Content posted by User on the BESST Products; b) any non-performance
            by User or breach of any contract or transaction with their
            customers through any BESST Products; c) breach of data privacy and
            confidentiality obligations; or d) fraud, willful misconduct or
            gross negligence or (e) any third party claims due to the Content
            posted by the User or use of the BESST in violation of these Terms
            by the User.
          </p>
          <h6>17. Severability</h6>
          <p>
            {" "}
            In the event that any provision of these Terms is determined to be
            unlawful, void or unenforceable, such provision shall nonetheless be
            enforceable to the fullest extent permitted by applicable law, and
            the unenforceable portion shall be deemed to be severed from these
            Terms, such determination shall not affect the validity and
            enforceability of any other remaining provisions.
          </p>
          <h6>18. Waiver</h6>
          <p>
            {" "}
            If BESST does not exercise a right under these Terms, shall not
            constitute a waiver of such right. Waiver of any right, remedy or
            breach of any subject matter contained in these Terms shall not be
            viewed as a waiver by BESST, unless specifically agreed by BESST in
            writing.
          </p>
          <h6>19. Force Majeure</h6>
          <p>
            {" "}
            If a Force Majeure Event occurs, the party that is prevented from
            performing (the “Non-performing Party”) will be excused from
            performing those obligations rendered un-performable by the Force
            Majeure Event. Upon promptly notifying the other party of the
            occurrence of such event, its effect on performance, and how long
            that party expects it to last. Thereafter, the Non-performing Party
            shall update that information as reasonably necessary. During a
            Force Majeure Event, the Non-performing Party shall use reasonable
            efforts to limit damages to the Performing Party and to resume its
            performance pursuant to these Terms.
          </p>
          <h6>20. Relationship of the parties</h6>
          <p>
            The User agrees that no joint venture, partnership, employment, or
            agency relationship exists between the User and BESST as a result of
            these Terms, the User’s use of the BESST Products, or the availing
            of Services from BESST.
          </p>
          <h6>21. Assignment</h6>
          <p>
            The User shall not assign or transfer the rights and obligations of
            the User under these Terms, including any assignment or transfer by
            reason of merger, reorganization, sale of all or substantially all
            of its assets, change of control or operation of law, without
            BESST’s prior written consent. BESST may assign or transfer the
            rights and obligations contained in there Terms of Service to any
            Person.
          </p>
          <h6>22. Governing law and jurisdiction</h6>
          <ul>
            <li>
              {" "}
              These Terms of Service will be governed by the laws of India
              without any application of conflict of laws principles.
            </li>
            <li>
              {" "}
              In case of any differences, disputes or disagreements between the
              User and BESST(“Dispute”), the User and BESST shall attempt to
              amicable resolve such dispute within 30 days from the date on
              which such Dispute arose.
            </li>
            <li>
              {" "}
              In case the User and BESST are unable to amicably resolve the
              Dispute within prescribed period from the date on which such
              Dispute arose, the Dispute may, at the option of the User or
              BESST, be referred to arbitration by a sole arbitrator appointed
              by BESST. The seat of arbitration shall be at Guwahati, Assam
              governed by the SIAC Rules. The award of the sole arbitrator shall
              be final and binding on the User and BESST.
            </li>
            <li>
              {" "}
              That the Courts at Guwahati, Assam shall have exclusive
              jurisdiction(subject to the arbitral proceedings which are to be
              also conducted at Guwahati, Assam) over any dispute arising out of
              this Terms and the parties hereby submit themselves to the
              jurisdiction of such competent Courts located in Guwahati, Assam.
            </li>
          </ul>
          <h6> 23. Confidentiality</h6>
          <p>
            For the purposes of this Terms, the term “Confidential Information ”
            shall mean and include all tangible and intangible information
            obtained, developed or disclosed or accessed including all
            documents, data papers and statements and trade secret of the User
            relating to its business practices and considered to be confidential
            and proprietary information. BESST shall strictly safeguard the
            Confidential Information and shall take all the necessary action to
            protect it against misuse. BESST shall be entitled to disclose the
            Confidential Information where:
          </p>
          <ul>
            <li>
              {" "}
              Where such Confidential Information is in or enters the public
            </li>
            <li> domain without BESST’s default;</li>
            <li>
              {" "}
              Where such Confidential Information is known to BESST at the time
              of first receipt, or thereafter becomes known to the BESST prior
              to such disclosure without similar restrictions from a source
              other than the User, as evidenced by written records;
            </li>
            <li>
              {" "}
              Where such Confidential Information is disclosed to employees,
              consultants, auditors, advisors and shareholders of the Party who
              are under a duty of confidentiality, or pursuant to Applicable Law
              or order of any Governmental Authority;
            </li>
            <li>
              {" "}
              Where such Confidential Information is required to be disclosed to
              partners of BESST for the provision of Services (including,
              without limitation, any services to be provided by the partners).
            </li>
            <li>
              {" "}
              Where such Confidential Information is required to be disclosed in
              compliance with the Applicable Laws.
            </li>
          </ul>
          <p>
            {" "}
            In case the User comes into possession of any Confidential
            Information of BESST the User shall safeguard the same and shall not
            disclose such Confidential Information without the prior written
            consent of BESST.
          </p>
          <h6>24. Contact Information</h6>
          <ul>
            <li>
              {" "}
              Questions about these Terms should be sent to BESST to
              support@BESST.in.
            </li>
            <li>
              {" "}
              Any notices regarding any query/grievance to BESST in connection
              with the BESST Products or these Terms may be sent to{" "}
              <b>Brahmaputra Exam Success Support Team Private Limited</b>. No.
              37, 2ND By Lane, B. R. Mazumdar Path, Baghorbari, Panjabari,
              Guwahati, Assam, 781037.
            </li>
            <li>
              {" "}
              Or may contact our Grievance Officer: Mr. Mukta Moni Mahanta at
              8822403212.
            </li>
            <li>
              {" "}
              Notice shall be sent to the contact address set forth here (as
              such may be changed by notice given to the other party), and shall
              be deemed delivered as of the date of actual receipt.
            </li>
            <li>
              {" "}
              The User’s agree that in case there are any changes in any
              information provided by the User to BESST, including the User’s
              phone number, e-mail address and other contact details, the User
              will be solely responsible to update them regularly. The User
              agrees that all agreements, notices, disclosures and other
              communications that BESST provide to the User’s electronically
              satisfy any legal requirement that such communications be in
              writing. If BESST sends any communication by e-mail or to the
              User’s phone number, it shall be sent to the User’s e-mail address
              or phone number available on the records created by the User on
              the BESST Products and it shall be deemed to have been received by
              the User once it is reflected as sent in the outbox of the BESST
              e-mail id or at the time of dispatch of the message.
            </li>
          </ul>
          <h6>25. Cumulative rights </h6>
          <p>
            {" "}
            The rights and remedies of BESST provided in this Terms are not
            exclusive, but are cumulative upon all other rights and remedies to
            the full extent allowed by law.
          </p>
          <h6> 26. Cancellation and Termination</h6>
          <ul>
            <li>
              {" "}
              You may cancel your account and terminate the Terms at any time by
              contacting BESST support team and then following the specific
              instructions indicated to you in BESST’ response section.
            </li>
            <li>
              {" "}
              Upon termination of the Services by either party for any reason:
            </li>
            <li>
              {" "}
              BESST will cease providing you the Services and you will no longer
              be able to access your account;
            </li>
            <li>
              {" "}
              Unless otherwise provided in the Terms, you will not be entitled
              to any refunds of any Fees, pro rata or otherwise;
            </li>
            <li>
              {" "}
              Any outstanding Fee owed to BESST for your use of the paid only
              Services through the effective date of such termination will
              immediately become due and payable in full.
            </li>
            <li>
              {" "}
              BESST reserves the right to modify or terminate the BESST
              Products, the Terms and/or Your account for any reason, without
              notice at any time. Termination of the Terms shall be without
              prejudice to any rights or obligations which arose prior to the
              date of termination.
            </li>
          </ul>
          <h6> 27. Entire understanding</h6>
          <p>
            These Terms constitutes the entire agreement between BESST and the
            User pertaining to the subject matter hereof and supersedes in their
            entirety all written or oral agreements between the BESST and the
            User.
          </p>
        </form>
      </div>
      {/* <LandingPageFooter /> */}
    </div>
  );
}
