import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Link, useLocation } from "react-router-dom";
import Cookies from "js-cookie";

function NavBar() {
  const [colorChange, setColorchange] = useState(false);
  // const changeNavbarColor = () => {
  //   if (window.scrollY >= 50) {
  //     setColorchange(true);
  //   } else {
  //     setColorchange(false);
  //   }
  // };
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (window.scrollY >= 50) {
      setColorchange(true);
    } else {
      setColorchange(false);
    }
    const checkLoginStatus = () => {
      setIsLoggedIn(Cookies.get("token") !== undefined);
    };

    checkLoginStatus();
  }, []);

  // window.addEventListener("scroll", changeNavbarColor);
  const isHomePage = location.pathname === "/";

  return (
    <Navbar
      className={colorChange && !isHomePage ? "navbar colorChange" : "navbar"}
      expand="lg"
      style={{ backgroundColor: colorChange ? "#FFF" : "#FFFFFF01" }}
    >
      <Container className="d-flex">
        <Link to="/">
          <Navbar.Brand>
            <img
              alt=""
              src={`${process.env.PUBLIC_URL}/assets/logo/7ac1037e455cb7559800318b98569c40.png`}
              width="100"
              height="100"
              flex-shrink="0"
              className="d-inline-block align-top mx-3"
            />{" "}
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle
          aria-controls="navbarScroll"
          style={{ width: "fit-content", marginRight: "10px", border: "none" }}
        />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="ms-auto my-2 my-lg-0 pl-4"
            // style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <Nav.Link className="menu-itm1" href="/">
              Home
            </Nav.Link>
            <NavDropdown
              className="menu-itm"
              title="Courses"
              id="navbarScrollingDropdown"
            >
              <NavDropdown.Item href="/cuetugcourse" style={{ backgroundColor: "white", color: "black" }}>
                CUET(UG)
              </NavDropdown.Item>
              <NavDropdown.Item href="/cuetexamcourse" style={{ backgroundColor: "white", color: "black" }}>
                CUET(PG)
              </NavDropdown.Item>
              <NavDropdown.Item href="/class12" style={{ backgroundColor: "white", color: "black" }}>
                NCERT CLASS XII
              </NavDropdown.Item>
              <NavDropdown.Item href="/class10" style={{ backgroundColor: "white", color: "black" }}>
                SEBA CLASS X
              </NavDropdown.Item>
              <NavDropdown.Item href="/best-ctet-coaching-in-delhi" style={{ backgroundColor: "white", color: "black" }}>
                CTET
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              className="menu-itm"
              title="Services"
              id="navbarScrollingDropdown"
            >
              <NavDropdown.Item href="/online-clases" style={{ backgroundColor: "white", color: "black" }}>
                Online Classes
              </NavDropdown.Item>
              <NavDropdown.Item href="/cuet-mock-test" style={{ backgroundColor: "white", color: "black" }}>
                Mock Test Series
              </NavDropdown.Item>
              <NavDropdown.Item href="/download-study-material" style={{ backgroundColor: "white", color: "black" }}>
                Downloadable Study Materials
              </NavDropdown.Item>
              <NavDropdown.Item href="/previous-year-question-bank" style={{ backgroundColor: "white", color: "black" }}>
                Previous Year Question Bank
              </NavDropdown.Item>
              <NavDropdown.Item href="/counseling-mock-registration" style={{ backgroundColor: "white", color: "black" }}>
                Counselling and Mock Registration for Admission in Central University
              </NavDropdown.Item>
              <NavDropdown.Item style={{ backgroundColor: "white", color: "black" }}>
                Complete Guidance for Admission Process of Delhi University
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link className="menu-itm" href="/blogs" >
              Blogs
            </Nav.Link>
            <Nav.Link className="menu-itm" href="/online-subscription" style={{ color: "#7B1FA2" }} >
              <p className="animate-charcter1 text-capitalize" style={{ fontSize: "18px" }}>Subscription</p><sup className="bg-warning" style={{ margin: "0px 5px", color: "#fff", padding: "5px 10px", borderRadius: "5px", fontSize: "10px" }}>New</sup>
            </Nav.Link>
            {/* <Nav.Link className="menu-itm" href="/subscription">
              Subscription
            </Nav.Link> */}
          </Nav>
          <Nav.Link
            className="menu-itm1"
            href={isLoggedIn ? "/student-dashboard" : "/signin"}
            style={{
              border: "1px solid #71269C",
              color: "#7B1FA2",
              borderRadius: "4px",
              boxSizing: "border-box",
              padding: "15px 20px",
              backgroundColor: "white",
              width: "fit-content",
              gap: "8px",
              textAlign: "center",
              marginLeft: "10px"
            }}
          >
            {isLoggedIn ? "Dashboard" : "Sign In / Sign Up"}
          </Nav.Link>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;
