import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col, Card } from "react-bootstrap";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLessThan, faGreaterThan } from '@fortawesome/free-solid-svg-icons';


const responsive = {
    superLargeDesktop: {
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const { carouselState: { currentSlide } } = rest;
    return (
        <div className="carousel-button-group_blog d-flex align-items-center justify-content-center">
            <button className={currentSlide === 0 ? 'disable d-flex align-items-center justify-content-center' : 'd-flex align-items-center justify-content-center'} onClick={() => previous()} ><FontAwesomeIcon icon={faLessThan} /></button>
            <button onClick={() => next()} className="d-flex align-items-center justify-content-center"> <FontAwesomeIcon icon={faGreaterThan} /> </button>
        </div>
    );
};

function Blog() {
    const [blogs, setBlogs] = useState([]);

    const blogData = [
        {
            date: "CUET PG Online Coaching: Ace Your Exam with the Right Preparation",
            image: "blog1.png",
            link: "/cuet-pg-online-coaching",
            name: "CUET PG Online Coaching: Ace Your Exam with the Right Preparation",
            shortContent: `Prepare for the CUET PG exam with expert-led online coaching. Discover the benefits, strategies, and tips to ace your CUET PG with top-notch guidance.`
        },
        {
            date: "Mastering CUET Exam Patterns: Strategies for Both UG and PG Aspirants",
            image: "blog1.png",
            link: "/cuet-preparation",
            name: "Mastering CUET: The Ultimate Guide to CUET Preparation and Success",
            shortContent: `Master CUET with our ultimate CUET preparation guide. Learn exam structure,
            effective strategies, and expert tips to excel in all sections. Your path to top central 
            universities starts here!...`
        },
        {
            date: "Class 10 SEBA Preparation Resources: Excel with BESST ",
            image: "blog1.png",
            link: "/blog",
            name: "Class 10 SEBA Preparation Resources: Excel with BESST ",
            shortContent: `BESST provides comprehensive Class 10 SEBA preparation resources, including
            MCQs, subjective questions, and expert guidance. Enroll today and start preparing for your exam!...`
        },
        {
            date: "Elevate Your CUET Preparation: BESST's Authentic CUET Mock Tests",
            image: "blog1.png",
            link: "/evevate-your-cuet-preperation",
            name: "Elevate Your CUET Preparation: BESST's Authentic CUET Mock Tests",
            shortContent: `Prepare smarter for CUET with BESST's CUET Mock Tests. Experience real exam conditions, analyze your performance, and master time management. Elevate your confidence for the CUET exam`
        },
        {
            date: "CUET Exam Anxiety and Stress Management Techniques",
            image: "blog1.png",
            link: "/anxiety-stress-management",
            name: "CUET Exam Anxiety and Stress Management Techniques",
            shortContent: `The Common University Entrance Test (CUET) is a gateway to prestigious universities in
            India, and preparation undoubtedly brings excitement and ambition. However, it's also
            natural for students to experience CUET exam anxiety, a potent mix of stress, fear, and
            worry that can hinder performance. Let's face it, the pressure to excel can feel
            overwhelming, impacting focus, clarity, and sleep. But overcoming CUET exam stress is
            achievable, and it starts with understanding its impact and equipping yourself with effective
            stress management techniques`
        },
        {
            date: "How to Prepare for CUET: Mastering the Exam with Proven Strategies",
            image: "blog1.png",
            link: "/CUET-study-tips",
            name: "How to Prepare for CUET: Mastering the Exam with Proven Strategies",
            shortContent: `Uncover valuable insights on how to prepare for CUET exam. Follow expert
            tips, practice effectively, and maximize your performance in the CUET examination `
        },

    ];
    const getBlogs = async () => {
        // try {
        //     const result = await axios.get(process.env.REACT_APP_BASE_URL  + '/homePageData/Blogs');
        //     setBlogs(result.data.data);
        //     console.log("blogs", result.data.data);
        // } catch (error) {
        //     console.log("blogs error", error);
        // }
        setBlogs(blogData);
    }

    useEffect(() => {
        getBlogs();
    }, []);

    const handleClick = (blogRounter) => {
        window.location.href = blogRounter;
    };

    return (
        <Container fluid className="bg-light padding_b_15px section bg-white magin_top_113px">
            <Col md={11} sm={11} xs={11} className="align_center">
                <p className='blog_head padding_t_25px padding_b_15px pb-4'>Blog</p>

                <Carousel
                    responsive={responsive}
                    className='row'
                    arrows={false}
                    renderButtonGroupOutside={true}
                    customButtonGroup={<ButtonGroup />}
                    swipeable={true}
                    draggable={true}
                    scrollable={true}
                    ssr={true}
                    infinite={false}
                    keyBoardControl={true}
                    itemClass="carousel-item-padding-40-px"
                    containerClass="carousel-container"
                >
                    {blogs?.map((item, index) => (
                        <Card key={index} className='p-2 blog_card' onClick={() => handleClick(item.link)}>
                            <div className="card_body align_center">
                                {/* <img src={`${process.env.PUBLIC_URL}/assets/placeholder.png`} alt={item.name} className="img-course" fluid /> */}
                                <div className="image-container">
                                    <img src={`${process.env.PUBLIC_URL}/assets/${index % 2 == 0 ? 'Group37.png' : 'Group 36.png'}`} alt='frame' className="img-course mt-0" fluid />
                                    <div className="overlay-text">
                                        {item.name}
                                    </div>
                                </div>
                                <div>
                                    <h5 className="blog_card_title ">{item.name}</h5>
                                </div>
                                <Col className='p-0'>
                                    <p>{item.shortContent.substring(0, 150)}...</p>
                                </Col>
                                <Row className="d-flex justify-content-center ml-2 mt-1">
                                    <Col md={9} sm={9} xs={12}>
                                        <button className='button_blog' onClick={() => handleClick(item.link)}>
                                            Read More <FontAwesomeIcon icon={faGreaterThan} className='Grater_then' />
                                        </button>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    ))}
                </Carousel>
            </Col>
        </Container>
    );
}

export default Blog;
