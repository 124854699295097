import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { postResponseDataActions } from "../../../../Redux/Slice/PostResponseDataSlice";
import { PostProfileDataThunk } from "../../../../Redux/Thunks/Post/PostProfileDataThunk";
import { payHistory, studDashboard } from "../../../../RouteConstants";
import { utilitySliceActions } from "../../../../Redux/Slice/UtilitySlice";
import { useLocation } from "react-router";


const AuthMenu = () => {
	const location = useLocation();
	const [currentRoute, setCurrentRoute] = useState("/");

	const { userProfileData, userProfileDataStatus } = useSelector(
		(state) => state.postResp
	);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	useEffect(() => {

		//getting profile details
		if (Object.keys(userProfileData).length === 0) {
			if (Cookies.get("token")) {
				let data = {
					email: Cookies.get("email"),
					token: Cookies.get("token"),
				};
				dispatch(PostProfileDataThunk(data));
				if (userProfileDataStatus === 403) {
					Cookies.remove("token");
					Cookies.remove("email");
					Cookies.remove("userId");
					navigate("/");
				}
			} else {
				navigate("/");
			}
		}

		console.log("1212124134", location);
		setCurrentRoute(location.pathname);

	}, []);

	//handles logout functionality
	const handleLogout = () => {
		Cookies.remove("token");
		Cookies.remove("email");
		Cookies.remove("userId");
		Cookies.remove("cuetPromoFlag");
		Cookies.remove("subscription_redirection");
		Cookies.remove("preferredCourseId");
		localStorage.clear();
		dispatch(postResponseDataActions.clearUserProfileData());
		dispatch(utilitySliceActions.setIsAuthenticated(false));

		window.location.href = "/";


	};

	return (
		<div className='nav-item'>
			<div className='dropdown' >
				<button className='dropbtn profile-shape' >
					{userProfileData.firstName &&
						userProfileData.firstName.split("")[0].toUpperCase()}
				</button>
				<div className='dropdown-content'>
					<Link to='/'>Home</Link>
					<Link onClick={() => { window.location.href = "/student-profile" }}> Update Profile</Link>
					{/* <Link to={studDashboard}>Dashboard</Link> */}
					{currentRoute == '/student-dashboard' ? undefined : (<Link onClick={() => window.location.href = "/student-dashboard"}>Dashboard</Link>)}
					<Link onClick={() => window.location.href = "/payment-list"}> Payment History </Link>
					<Link to='/' onClick={handleLogout}>
						Logout
					</Link>
				</div>
			</div >
		</div >
	);
};

export default AuthMenu;
