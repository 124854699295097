import React from "react";
import { Button, Row, Col, Card, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaQuoteLeft, FaQuoteRight, FaRegAddressBook } from "react-icons/fa";
import Tonmoy from "../../../Assets/images/tonmoy.png";
import Gunjan from "../../../Assets/images/gunjan.svg";
import Rukh from "../../../Assets/images/rukh.png";
import { useEffect, useState } from "react";
import axios from "axios";

const SuccessStory = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          "https://www.besst.in/besstMainApi/df/statistics",
          {},
          {
            headers: {
              "Content-Type": "application/json",
              // "Access-Control-Allow-Origin": "*",
              Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
            },
          }
        );
        let unfilteredData = response.data.Data;
        const entries = Object.entries(unfilteredData);
        const filteredEntries = entries.slice(1, -1);
        setData(filteredEntries);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Invoke the async function
  }, []);
  // const data = [
  //   {
  //     icon: "./assets/Iconss/5.svg",
  //     number: "67.1k",
  //     name: "Students",
  //   },
  //   {
  //     icon: "./assets/Iconss/3.svg",
  //     number: "26k",
  //     name: "Certified Instructor",
  //   },
  //   {
  //     icon: "./assets/Iconss/2.svg",
  //     number: 72,
  //     name: "Country Language",
  //   },
  //   {
  //     icon: "./assets/Iconss/1.svg",
  //     number: "99.9%",
  //     name: "Success Rate",
  //   },
  //   {
  //     icon: "./assets/Iconss/5.svg",
  //     number: 57,
  //     name: "Trusted Companies",
  //   },
  // ];
  return (
    <div className="">
      <div className="Contact_heading container-fluid mb-5">
        <div className="Contact_content">Success Stories</div>
        <div className="Contact_slashContent">Home / Success Stories</div>
      </div>
      <Row className="m-5">
        <Col md={6}>
          <div className="heading-content" style={{ paddingRight: "44px" }}>
            <h2 style={{ textAlign: "left", color: "black" }}>
              We have Stories to inspire you
            </h2>
            <p style={{ fontSize: "18px", textAlign: "justify" }}>
              My name is Mrigakhi Mahanta. I have been using BESST for a few
              months. BESST (Brahmaputra Exam Success Support Team) conducts
              mock tests and provides live classes, which I think, is very
              important for anyone who would appear for any competitive exams.
              Being the user of the portal, I would like to say that I am very
              much glad to be a part of this team as it helps me to learn in
              quite a short span of time. It has also helped me to identify my
              weak points.
            </p>
            <button
              className="btn btn-primary testimonials-button"
              style={{
                background: "#71269C",
                borderRadius: "4px",
                width: "fit-content",
              }}
              onClick={() => window.location.href = "/contact"}
            >
              Get in Touch
            </button>
          </div>
        </Col>
        <Col md={6} className="d-flex align-item-center justify-content-center">
          <div
            className="video-container"
            style={{ width: "min-content", borderRadius: "10px" }}
          >
            <video
              width="100%"
              height="500px"
              src={`${process.env.PUBLIC_URL}/assets/testimonials/testimonial-video.mp4`}
              type="video/mp4"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              controls
            ></video>
            <div className="video-description">
              <span className="name">Mrigakhi Mahanta</span>-
              <span className="course" style={{ whiteSpace: "nowrap" }}>
                Course:CUET(UG)
              </span>
            </div>
          </div>
        </Col>
      </Row>

      {/* Second Row */}
      <Row className="m-5">
        <Col md={4}>
          <Card className="text-center">
            {/* Top image */}
            <Card.Img variant="top" style={{ height: "272px" }} src={Tonmoy} />

            {/* Bottom content */}
            <Card.Body className="testimonial-card-body">
              <FaQuoteLeft style={{ fill: "#7B1FA2" }} className="quote-icon" />

              <blockquote className="blockquote mb-0">
                <p style={{ textAlign: "justify", marginBottom: "2rem" }}>
                  During my CUET exam preparations, I took live classes on
                  General Test papers provided by www.besst.in.I also practised
                  their mock tests.{" "}
                </p>
                <p style={{ textAlign: "left" }} className="testimonial-name">
                  Tonmoy Kashyap
                </p>
                <p style={{ textAlign: "left" }} className="testimonial-course">
                  Course:CUET (UG) Live
                </p>
              </blockquote>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="text-center">
            {/* Top image */}
            <Card.Img variant="top" style={{ height: "272px" }} src={Gunjan} />

            {/* Bottom content */}
            <Card.Body className="testimonial-card-body">
              <FaQuoteLeft style={{ fill: "#7B1FA2" }} className="quote-icon" />

              <blockquote className="blockquote mb-0">
                <p style={{ textAlign: "justify", marginBottom: "2rem" }}>
                  BESST played a vital role in my CUET preparation. The mock
                  test stood out in quality and helped me in identifying my
                  weaknesses in{" "}
                </p>
                <p style={{ textAlign: "left" }} className="testimonial-name">
                  Gunjan Poddar
                </p>
                <p style={{ textAlign: "left" }} className="testimonial-course">
                  Course:CUET (UG) Live
                </p>
              </blockquote>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="text-center">
            {/* Top image */}
            <Card.Img variant="top" style={{ height: "272px" }} src={Rukh} />

            {/* Bottom content */}
            <Card.Body className="testimonial-card-body">
              <FaQuoteLeft style={{ fill: "#7B1FA2" }} className="quote-icon" />

              <blockquote className="blockquote mb-0">
                <p style={{ textAlign: "justify", marginBottom: "2rem" }}>
                  I'm glad that I got to learn about CUET and its fundamentals
                  from BESST. It helped me get into a college in Noida. I'm
                  thankful to you all{" "}
                </p>
                <p style={{ textAlign: "left" }} className="testimonial-name">
                  Rukh Acharya
                </p>
                <p style={{ textAlign: "left" }} className="testimonial-course">
                  Bhubaneswar, Odisha
                </p>
              </blockquote>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Third Row */}
      <Row
        className="p-5 align-items-center"
        style={{ margin: "0px", backgroundColor: "rgba(123, 31, 162, 0.05)" }}
      >
        <Col md={12} className="text-center">
          <div className="user-info">
            <h3>Hear from happy customers</h3>
            <Image
              src={`${process.env.PUBLIC_URL}/assets/testimonials/gunjan.svg`}
              roundedCircle
              className="user-image"
              style={{ margin: "24px 0", height: "150px", width: "150px", backgroundColor: "#fff" }}
            />
            <div className="user-content text-center mt-4 px-5 py3">
              <p
                style={{ padding: "0 24px", textAlign: "center" }}
              >
                <div
                  style={{
                    display: "inline-block",
                    textAlign: "left",
                    paddingRight: "4px",
                  }}
                >
                  <FaQuoteLeft style={{ fill: "#7B1FA2" }} />
                </div>
                I have taken online classes with Kaushik Sir; he was the perfect
                trainer for me I could have imagined. He has been very patient
                with me and always very supportive. He starts from basics and
                gradually gets into advanced topics. The study material that sir
                provided was very useful; students with any level of knowledge
                can start, learn, and excel. Its practice papers and mock test
                papers helped me a lot, and the materials of Cuet and UG are
                very informative. Thank you sir for your support 😎
                <div
                  style={{
                    textAlign: "right",
                    display: "inline-block",
                    paddingLeft: "4px",
                  }}
                >
                  <FaQuoteRight style={{ fill: "#7B1FA2" }} />
                </div>
              </p>

              <p style={{ margin: "24px 0" }}>
                <span className="testimonial-name">Parashmoni Boruah</span> -
                Course: CUET (UG) Live
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="p-5 justify-content-center">
        {data.map(([key, value], index) => (
          <Col key={index} lg={2} md={6} className="text-center mb-4">
            <div
              style={{
                display: "flex",
                gap: "12px",
                alignItems: "flex-start",
              }}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/./assets/Iconss/${index}.svg `}
                roundedCircle
                className="user-image"
              />
              <div style={{ textAlign: "left" }}>
                <p
                  className="number"
                  style={{ fontSize: "26px", fontWeight: "bold", margin: 0 }}
                >
                  {value}
                </p>
                <p className="name" style={{ margin: 0 }}>
                  {key}
                </p>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default SuccessStory;
