import axios from "axios";
import Cookies from "js-cookie";
import { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import { ThreeDots } from "react-loader-spinner";
import { Tabs, Tab, Container, Row, Col, Nav } from "react-bootstrap";
import OneLinerFooter from "../../Components/Global/Footers/OneLinerFooter";
import Header from "../../Components/Global/Navigation/Header";
import useRemoveModal from "../../Components/useRemoveModal";
import useCouponSelect from "./PaymentComponent/CouponSelect";
import NavBar from "../../Components/Copy/Navbar/Navbar";
import Swal from "sweetalert2";
import AddressModal from "../../Components/Payment/Modal/AddressModal";
import paymentHandler from "./pay";
import { Form } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import DomainSubjectsModal from "../../Components/Payment/Modal/DomainSubjectsModal";
import SubscriptionCard from "../../Components/Payment/SubscriptionCard";
import { FaChevronLeft } from "react-icons/fa";
import { GetCoursesThunk } from "../../Redux/Thunks/Get/GetCoursesThunk";
import { useDispatch, useSelector } from "react-redux";
import OrderSummary from "../../Components/Payment/Modal/OrderSummary";

const breakpoints = {
    375: {
        slidesPerView: 1,
    },
    790: {
        slidesPerView: 2,
        spaceBetweenSlides: 20,
    },
    1150: {
        slidesPerView: 3,
        spaceBetweenSlides: 30,
    },
    1550: {
        slidesPerView: 4,
        spaceBetweenSlides: 40,
    },
    1900: {
        slidesPerView: 5,
        spaceBetweenSlides: 50,
    },
};

const fallbackMessage = "Currently, no subscription pack is available. ";

const PaymentNew = () => {
    const navigate = useNavigate();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [currentCounse, setCurrentCourse] = useState('Common University Entrance Test (UG)');
    const [subscriptionDetails, setSubscriptionDetails] = useState([]);
    const [courseId, setCourseId] = useState(1);
    const [actualPriceTotal, setActualPriceTotal] = useState(0);
    const [basePriceTotal, setBasePriceTotal] = useState(0);
    const [GSTTotal, setGSTTotal] = useState(0);
    const [priceConfigurations, setPriceConfiguration] = useState([]);
    const [loader, setLoader] = useState(false);
    const [profileData, setProfileData] = useState(null);
    const [chosenDomainSubject, setChosenDomainSubject] = useState([]);
    const [chosenLanguageSubject, setChosenLanguageSubject] = useState([]);
    const [isAddNewAddress, setIsAddNewAddress] = useState(false);
    const [cityList, setCityList] = useState([]);
    const [useAddressDetails, setUserAddressDetails] = useState({});
    const [useAddressDetailsTemp, setUserAddressDetailsTemp] = useState({});
    const [appliedCoupon, setAppliedCoupon] = useState("");
    const [couponValidationError, setCouponValidationError] = useState("");
    const [couponValidationSuccess, setCouponValidationSuccess] = useState("");
    const [finalSecletedData, setFinalSelectedData] = useState({});
    const [couponDetails, setCouponDetails] = useState(null);
    const [orderId, setOrderId] = useState("");
    const [mid, setMid] = useState("");
    const [txnToken, setTxnToken] = useState("");
    const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
    const [isCouponApplied, setIsCouponApplied] = useState(0);
    const [selectEvent, setSelectEvent] = useState(false);
    //API To Get Subscription Details

    const checkLoginStatus = () => {
        // //console.log("tokenValue", Cookies.get("token"));
        setIsLoggedIn(Cookies.get("token") !== undefined);
    };

    useEffect(() => {
        checkLoginStatus();
    }, []);



    const infoPopup = (message) => {
        Swal.fire({
            icon: "info",
            html: `${message}`,
            timer: 4000,
            showConfirmButton: false,
        });
    }
    const getCityList = () => {
        // //console.log(useAddressDetailsTemp, profileData, ">>>>>>>>>>");
        const state = profileData?.stateList.find((state) => state.stateCode == useAddressDetailsTemp?.state);

        // //console.warn(">>>>>>>", state);
        if (state.stateCode) {
            axios
                .get(
                    `${process.env.REACT_APP_BASE_URL}/df/getDistrictsForState/${state.stateCode}`,
                    {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Client_ID": "MVOZ7rblFHsvdzk25vsQpQ==",
                            "Authorization": `${Cookies.get("token")}`,
                        },
                    }
                )
                .then((res) => {
                    // //console.log(res.data.Data);
                    setCityList(res.data.Data);
                })
                .catch((error) => {
                    // //console.log(error)
                });
        }
    };

    useEffect(() => {
        if (Cookies.get("token")) {
            setIsUserLoggedIn(true);
            axios.post(
                `${process.env.REACT_APP_BASE_URL}/profileData`,
                {
                    email: Cookies.get("email"),
                },
                {
                    headers: {
                        "Acces-Control-Allow-Origin": "*",
                        "Client_ID": "MVOZ7rblFHsvdzk25vsQpQ==",
                        "Authorization": `${Cookies.get("token")}`,
                    },
                }
            ).then(async response => {
                if (response.status == 200) {

                    const userProfile = response.data.Data;
                    setProfileData(userProfile);
                    // //console.log(">>>>", userProfile, profileData)

                    if (response.data.Data?.state) {
                        const state = response.data.Data?.stateList.find((state) => state.stateName == response.data.Data?.state);
                        // //console.log(state, response.data.Data?.state);
                        setUserAddressDetails({
                            address: response.data.Data.address,
                            state: state?.stateCode,
                            stateName: state?.stateName,
                            city: response.data.Data.city,
                            pincode: response.data.Data.pincode
                        });
                        setUserAddressDetailsTemp({
                            address: response.data.Data.address,
                            state: state?.stateCode,
                            stateName: state?.stateName,
                            city: response.data.Data.city,
                            pincode: response.data.Data.pincode
                        });
                    }

                    getBillingAddressDetails();
                }
            }).catch(error => {
                //console.log(error);
            })
        }
    }, []);


    const getBillingAddressDetails = () => {
        if (Cookies.get("token")) {
            fetch(`${process.env.REACT_APP_BASE_URL}/pg/getUserBillingAddr`, {
                method: "GET",
                headers: {
                    "Acces-Control-Allow-Origin": "*",
                    Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
                    Authorization: `${Cookies.get("token")}`,
                },
            })
                .then((response) => response.json())
                .then((result) => {
                    if (result.Data?.state) {
                        setUserAddressDetailsTemp({
                            address: result.Data.address,
                            state: result.Data.state,
                            city: result.Data.city,
                            stateName: result.Data.stateName,
                            pincode: result.Data.pincode
                        })

                        setUserAddressDetails({
                            address: result.Data.address,
                            state: result.Data.state,
                            stateName: result.Data.stateName,
                            city: result.Data.city,
                            pincode: result.Data.pincode
                        });
                        // getCityList();
                    }

                    if (!useAddressDetails?.state) {
                        setIsAddNewAddress(true);
                    } else {
                        setIsAddNewAddress(false);
                    }
                })
                .catch((err) => {
                    //console.log(err);
                });
        }
    }

    useEffect(() => {
        axios.get(
            `${process.env.REACT_APP_BASE_URL}/df/getAllSubscriptionPacksForOnlineClasses/${courseId}`,
            {
                headers: {
                    "Acces-Control-Allow-Origin": "*",
                    "Client_ID": "MVOZ7rblFHsvdzk25vsQpQ==",
                    "Authorization": `${Cookies.get("token")}`,
                },
            }
        ).then(response => {
            setSubscriptionDetails(response.data.Data);
            setPriceConfiguration(response.data.Data[0]?.priceConfigurations);
        }).catch(error => {
            //console.log(error);
        });
    }, []);

    useEffect(() => {
        if (mid && orderId && txnToken) {
            //console.log(document.getElementById("paytm-form"));
            document.getElementById("paytm-form").submit();
        }
    }, [mid, orderId, txnToken]);

    const createCheckboxChangeHandler = (event, data, isLanguage, section, domainType) => {
        //console.log(data);
        const { checked } = event.target;
        const extraData = event.target.dataset.extraData;
        if (checked) {
            if (isLanguage) {
                setChosenLanguageSubject((prevState) => {
                    return [
                        ...prevState, data
                    ]
                })
            } else {
                ;
                setChosenDomainSubject((prevState) => {
                    return [
                        ...prevState, data
                    ]

                })
            }

            updateFinalSelectedData(section, domainType, data);
            calculateBilling(extraData, 1, data);
        } else {
            if (isLanguage) {
                const arrayWithoutData = chosenLanguageSubject.filter(ele => ele.topicId != data.topicId);
                setChosenLanguageSubject(arrayWithoutData);
            } else {
                const arrayWithoutData = chosenDomainSubject.filter(ele => ele.topicId != data.topicId);
                setChosenDomainSubject(arrayWithoutData);
            }
            removeDataFromFinalSelectedData(section, domainType, data);
            calculateBilling(extraData, 0, data);
        }
    }

    const updateFinalSelectedData = (section, domainType, data) => {
        setFinalSelectedData(prevState => {
            const updatedState = { ...prevState };
            const sectionKey = `Section ${section}`;
            updatedState[sectionKey] = updatedState[sectionKey] || {};
            updatedState[sectionKey][domainType] = updatedState[sectionKey][domainType] || [];
            updatedState[sectionKey][domainType].push(data);
            return updatedState;
        });
    };

    const removeDataFromFinalSelectedData = (section, domainType, dataToRemove) => {
        setFinalSelectedData(prevState => {
            const updatedState = { ...prevState };
            const sectionKey = `Section ${section}`;
            if (updatedState[sectionKey] && updatedState[sectionKey][domainType]) {
                updatedState[sectionKey][domainType] = updatedState[sectionKey][domainType].filter(data => data !== dataToRemove);
            }
            return updatedState;
        });
    };

    const calculateBilling = (extraData, operation, data) => {
        const selectedPlan = priceConfigurations?.find((ele) => ele.topicId == data.topicId);
        if (operation) {
            setActualPriceTotal(actualPriceTotal + selectedPlan?.actualPrice);
            setBasePriceTotal(basePriceTotal + selectedPlan?.basePrice)
            setGSTTotal(GSTTotal + selectedPlan?.gstPrice)
        } else {
            setActualPriceTotal(actualPriceTotal - selectedPlan?.actualPrice);
            setBasePriceTotal(basePriceTotal - selectedPlan?.basePrice)
            setGSTTotal(GSTTotal - selectedPlan?.gstPrice)
        }
    }

    const handleChange = (e) => {
        let { name, value } = e.target;
        //console.log(value, name);
        if (name === "city") {
            setSelectEvent(true);
        } else {
            setSelectEvent(false);
        }

        if (name == "state") {
            const state = profileData?.stateList.find((state) => state.stateName == value);
            setUserAddressDetailsTemp(prevState => ({
                ...prevState,
                "stateName": state.stateName,
                "state": state.stateCode
            }));
        } else {
            setUserAddressDetailsTemp(prevState => ({
                ...prevState,
                [name]: value
            }));
        }

        //console.warn(">>>>>>>>", useAddressDetailsTemp);
    };

    const handleCouponChange = (e) => {
        setAppliedCoupon(e.target.value)
    };

    const updateAddress = () => {
        //console.log(useAddressDetails);
        const state = profileData?.stateList.find((state) => state.stateName == (useAddressDetailsTemp?.stateName || useAddressDetailsTemp?.state));
        //console.log(state);
        // //console.log({
        //     email: profileData?.email,
        //     address: useAddressDetailsTemp?.address,
        //     city: useAddressDetailsTemp?.city,
        //     state: state?.stateCode,
        //     pincode: useAddressDetailsTemp?.pincode,
        // });
        if (!state?.stateCode || !useAddressDetailsTemp?.address || !useAddressDetailsTemp?.pincode || !useAddressDetailsTemp?.city) {
            alert("Enter valid address");
            return;
        }

        if (!(/^[0-9]+$/.test(useAddressDetailsTemp?.pincode)) || useAddressDetailsTemp?.pincode.length < 6) {
            alert("Enter valid pincode");
            return;
        }

        if (!(/^[A-Za-z ]+$/.test(useAddressDetailsTemp?.city)) || useAddressDetailsTemp?.city.length < 2) {
            alert("Enter valid address");
            return;
        }

        axios.post(
            `${process.env.REACT_APP_BASE_URL}/pg/saveUserBillingAddr`,
            {
                email: profileData?.email,
                address: useAddressDetailsTemp?.address,
                city: useAddressDetailsTemp?.city,
                state: state?.stateCode,
                pincode: useAddressDetailsTemp?.pincode,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Acces-Control-Allow-Origin": "*",
                    Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
                    Authorization: `${Cookies.get("token")}`,
                },
            }
        ).then((result) => {
            //console.log(useAddressDetailsTemp);
            getBillingAddressDetails();
            setUserAddressDetails(useAddressDetailsTemp);
            setIsAddNewAddress(false);
        });
    };


    const handlePayment = () => {
        if (!Cookies.get("token")) {
            navigate("/signin");
            return;
        }

        if (!useAddressDetails.address || !useAddressDetails.state || !useAddressDetails.city || !useAddressDetails.pincode) {
            alert("Please enter valid address");
            return;
        }

        if (chosenDomainSubject.length == 0 && chosenLanguageSubject.length == 0) {
            alert("Please select at least one subject to proceed");
            return;
        }

        setLoader(true);
        paymentHandler({
            couponCode: isCouponApplied ? appliedCoupon : "",
            profileData,
            totalPrice: isCouponApplied ? couponDetails?.totalPayableAmt : actualPriceTotal.toFixed(2),
            setLoader,
            checkout: {
                chosenDomainSubjects: chosenDomainSubject,
                chosenLangSubjects: chosenLanguageSubject,
                subscriptionId: subscriptionDetails[0]?.subscriptionId,
            },
            chosenSectionWiseDetails: finalSecletedData,
            setOrderId: setOrderId,
            setTxnToken: setTxnToken,
            setMid: setMid,
        });
    }

    const clearApplyCoupon = () => {
        setAppliedCoupon("");
        setCouponValidationError("");
    }

    const applyCoupon = () => {
        if (!Cookies.get("token")) {
            navigate("/signin");
            return;
        }

        if (chosenDomainSubject.length == 0 && chosenLanguageSubject.length == 0) {
            alert("Please select at least one subject to proceed");
            return;
        }
        if (appliedCoupon !== "" || true) {
            //console.log(subscriptionDetails);
            const obj = {
                "subscriptionId": subscriptionDetails[0]?.subscriptionId,
                "appliedCoupon": appliedCoupon,
                "chosenDomainSubjects": chosenDomainSubject,
                "chosenLangSubjects": chosenLanguageSubject,
                "chosenSectionWiseDetails": finalSecletedData
            };

            axios.post(
                `${process.env.REACT_APP_BASE_URL}/pg/validateCoupon`,
                obj,
                {
                    headers: {
                        "Acces-Control-Allow-Origin": "*",
                        Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
                        Authorization: `${Cookies.get("token")}`,
                    },
                }
            ).then((res) => {
                //console.log(res.data);
                if (res.data.ResultCode == "403") {
                    setCouponValidationError(res.data.ResultMessage);
                } else if (res.data.ResultCode == "200") {
                    setIsCouponApplied(1);
                    setCouponDetails(res.data.Data);
                    setCouponValidationSuccess("Coupon Applied")
                }
            }).catch((error) => {
                //console.log(error);
            });
        }
    }

    useEffect(() => {
        //console.log(useAddressDetailsTemp, profileData, ">>>>>>>>>>");
        let state;
        if (profileData?.stateList) {
            state = profileData?.stateList.find((state) => state.stateCode == useAddressDetailsTemp?.state);
        }

        //console.warn(">>>>>>>", state);
        if (state?.stateCode) {
            axios
                .get(
                    `${process.env.REACT_APP_BASE_URL}/df/getDistrictsForState/${state.stateCode}`,
                    {
                        headers: {
                            "Access-Control-Allow-Origin": "*",
                            "Client_ID": "MVOZ7rblFHsvdzk25vsQpQ==",
                            "Authorization": `${Cookies.get("token")}`,
                        },
                    }
                )
                .then((res) => {
                    //console.log(res.data.Data);
                    setCityList(res.data.Data);
                })
                .catch((error) => {
                    //console.log(error)
                });
        }
    }, [useAddressDetailsTemp, profileData]);

    return (
        <>
            <NavBar />
            <form
                id="paytm-form"
                method="post"
                action={`https://securegw.paytm.in/theia/api/v1/showPaymentPage?mid=${mid}&orderId=${orderId}`}
            >
                <table border="1">
                    <tbody>
                        <input type="hidden" name="mid" value={mid} />
                        <input type="hidden" name="orderId" value={orderId} />
                        <input type="hidden" name="txnToken" value={txnToken} />
                    </tbody>
                </table>
            </form>
            <Container fluid className="">
                <Row className="new-subscription">
                    <Col md={8} sm={12} xs={12} className="new-subscription-left">
                        <Row className="py-2">
                            <Col md={12} className="d-flex align-items-center">
                                <h2 className="subscription-heading">{currentCounse}</h2>
                                <div className="bg-warning py-2 px-4 text-light" style={{ borderRadius: "10px" }}>
                                    {subscriptionDetails[0]?.tagLine}
                                </div>
                            </Col>
                            <Col md={12}>
                                <Row className=" subscription-rating mt-3">
                                    <Col md={4} sm={12} className="p-1">
                                        <div style={{ border: "2px solid #7b1fa2", fontSize: "16px", fontWeight: "500", color: "#7b1fa2", backgroundColor: "#7a1ea269", padding: "10px 20px", borderRadius: "10px" }}>
                                            {subscriptionDetails[0]?.subscriptionName}
                                        </div>
                                    </Col>
                                    <Col md={4} sm={12} className="p-1">
                                        <div style={{ cursor: "pointer", fontSize: "16px", fontWeight: "500", color: "#7b1fa2", backgroundColor: "#7a1ea269", padding: "10px 20px", borderRadius: "10px" }} onClick={() => window.location.href = "/mock-subscription"}>
                                            CUET Mock Series Subscription
                                        </div>
                                    </Col>
                                    <Col md={4} sm={12} className="p-1">
                                        <div style={{ cursor: "pointer", fontSize: "16px", fontWeight: "500", color: "#7b1fa2", backgroundColor: "#7a1ea269", padding: "10px 20px", borderRadius: "10px" }} onClick={() => window.location.href = "/subscription"}>
                                            CUET / Other Subscription
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={12}>
                                <p>The program combines live online instruction, independent learning activities, and practical application for a well-rounded learning experience.</p>
                            </Col>
                            <Col md={12}>
                                <div className="subscription-featurs py-4">
                                    <h2>Customization Available</h2>
                                    <small>Option for customization is also available. Select your choice of subjects from the list below, to create your program. The more the no. of subjects in your cart, the greater will be the fee waiver.</small>
                                    <div>
                                        <Nav variant="underline" defaultActiveKey="link-1">
                                            <Nav.Item className="d-flex w-100">
                                                <Nav.Link eventKey="link-1" className="new-nav-link w-100">Language</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Row>
                                            <Col md={12} className="m-0 p-0">
                                                {
                                                    subscriptionDetails[0]?.sectionWiseDetails?.["Section 1"]?.Language?.map((ele) => {
                                                        return (
                                                            <Form.Group className="m-0 customizable-field d-flex align-items-center" controlId={ele?.topicId}>
                                                                {ele?.selection ? <Form.Check type="checkbox" checked={ele?.selection} disabled={ele?.selection} label={ele?.topicName} /> : !ele?.isLimitExceed ? < Form.Check type="checkbox" label={ele?.topicName} onChange={(event) => createCheckboxChangeHandler(event, ele, 1, 1, "Language")} data-extra-data={"Language"} /> : <><Form.Check type="checkbox" disabled={true} label={ele?.topicName} /><span onClick={() => infoPopup(ele?.subscriptionMessage)} lassName="bg-warning text-white d-flex align-items-center justify-content-center" style={{ fontSize: "15px", height: "20px", width: "20px", borderRadius: "20px" }}>i</span></>}
                                                            </Form.Group>
                                                        )
                                                    })
                                                }
                                            </Col>
                                        </Row>
                                        <Nav variant="underline" defaultActiveKey="link-1">
                                            <Nav.Item className="d-flex w-100">
                                                <Nav.Link eventKey="link-1" className="new-nav-link w-100">Subject</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Row>
                                            <Col md={12} className="m-0 p-0">
                                                <Row>
                                                    {subscriptionDetails.length > 0 && Object.keys(subscriptionDetails[0]?.sectionWiseDetails?.["Section 2"]).map((subject) => {
                                                        return (<Col md={4} className="m-0 p-0">
                                                            <p className="lead text-secondary course-heading-new">{subject}</p>
                                                            <ul style={{ margin: "0px 0px 0px -20px", listStyle: "none", padding: "0" }}>
                                                                {subscriptionDetails[0]?.sectionWiseDetails?.["Section 2"]?.[subject].map((ele) => {
                                                                    return (
                                                                        <li className="">
                                                                            <Form.Group className="m-0 customizable-field d-flex align-items-center" controlId={ele?.topicId}>
                                                                                {ele?.selection ? <Form.Check type="checkbox" checked={ele?.selection} disabled={ele?.selection} label={ele?.topicName} /> : !ele?.isLimitExceed ? <Form.Check type="checkbox" label={ele?.topicName} onChange={(event) => createCheckboxChangeHandler(event, ele, 0, 2, subject)} data-extra-data={subject} /> : <><Form.Check type="checkbox" disabled={true} label={ele?.topicName} /><span onClick={() => infoPopup(ele?.subscriptionMessage)} className="bg-warning text-white d-flex align-items-center justify-content-center" style={{ fontSize: "15px", height: "20px", width: "20px", borderRadius: "20px" }}>i</span></>}
                                                                            </Form.Group>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </Col>)
                                                    })}
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Nav variant="underline" defaultActiveKey="link-1">
                                            <Nav.Item className="d-flex w-100">
                                                <Nav.Link eventKey="link-1" className="new-nav-link w-100">General Test</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                        <Row>
                                            <Col md={12} className="m-0 p-0">
                                                <Row>
                                                    {subscriptionDetails.length > 0 && Object.keys(subscriptionDetails[0]?.sectionWiseDetails?.["Section 3"]).map((subject) => {
                                                        return (<Col md={4} className="m-0 p-0">
                                                            <p className="lead text-secondary course-heading-new">{subject}</p>
                                                            <ul style={{ margin: "0px 0px 0px -20px", listStyle: "none", padding: "0" }}>
                                                                {subscriptionDetails[0]?.sectionWiseDetails?.["Section 3"]?.[subject].map((ele) => {
                                                                    return (
                                                                        <li className="">
                                                                            <Form.Group className="m-0 customizable-field d-flex align-items-center" controlId={ele?.topicId}>
                                                                                {ele?.selection ? <Form.Check type="checkbox" checked={ele?.selection} disabled={ele?.selection} label={ele?.topicName} /> : !ele?.isLimitExceed ? <Form.Check type="checkbox" label={ele?.topicName} onChange={(event) => createCheckboxChangeHandler(event, ele, 0, 2, subject)} data-extra-data={subject} /> : <><Form.Check type="checkbox" disabled={true} label={ele?.topicName} /><span onClick={() => infoPopup(ele?.subscriptionMessage)} className="bg-warning text-white d-flex align-items-center justify-content-center" style={{ fontSize: "15px", height: "20px", width: "20px", borderRadius: "20px" }}>i</span></>}
                                                                            </Form.Group>
                                                                        </li>
                                                                    )
                                                                })}
                                                            </ul>
                                                        </Col>)
                                                    })}
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={4} sm={12} xs={12} className="new-subscription-right">
                        {isUserLoggedIn ? <div className="address-card-new p-3 mb-3">
                            <div className="address-card-new-header mt-3">
                                <h2>Billing Address</h2>
                                <p onClick={() => setIsAddNewAddress(!isAddNewAddress)}>{!isAddNewAddress ? "Add New Address" : "Use Existing Address"}</p>
                            </div>
                            <div className="address-card-new-body px-4 py-2">
                                {
                                    !isAddNewAddress ?
                                        <>

                                            <p className="m-0 text-secondary">{useAddressDetails?.stateName ? `${useAddressDetails?.address} - ${useAddressDetails?.city}, ${useAddressDetails?.stateName} - ${useAddressDetails?.pincode}` : `No Address`}</p>
                                        </> :
                                        <>
                                            <div className='w-100'>
                                                <label
                                                    htmlFor='address'
                                                    className='modal-profile-label'
                                                    style={{ fontWeight: "400" }}
                                                >
                                                    Address
                                                </label>
                                                <textarea
                                                    type='text'
                                                    rows='4'
                                                    placeholder='Address'
                                                    className='modal-profile-fields'
                                                    id='address'
                                                    name='address'
                                                    style={{ borderColor: "#ced4da" }}
                                                    value={useAddressDetailsTemp?.address}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className='w-100'>
                                                <label
                                                    htmlFor='state'
                                                    className='modal-profile-label mt-2'
                                                    style={{ fontWeight: "400" }}
                                                >
                                                    State
                                                </label>
                                                <select
                                                    className='modal-profile-fields'
                                                    aria-label='Default select example'
                                                    name='state'
                                                    style={{ width: "100%", padding: 10, borderColor: "#ced4da" }}
                                                    onChange={handleChange}
                                                // value={useAddressDetailsTemp?.stateName}
                                                >
                                                    <option
                                                        value='null'
                                                    >
                                                        SELECT THE STATE
                                                    </option>
                                                    {profileData?.stateList.length > 0
                                                        ? profileData?.stateList.map((item, index) => (
                                                            <option
                                                                key={index}
                                                                value={item.stateName}
                                                            >
                                                                {item.stateName}
                                                            </option>
                                                        ))
                                                        : ""}
                                                </select>
                                            </div>
                                            <div className='position-relative w-100'>
                                                <label
                                                    htmlFor='dist'
                                                    className='modal-profile-label mt-2'
                                                    style={{ fontWeight: "400" }}
                                                >
                                                    District
                                                </label>
                                                <input
                                                    type='text'
                                                    autoComplete='off'
                                                    id='dist'
                                                    className='modal-profile-fields'
                                                    name='city'
                                                    placeholder='District'
                                                    style={{ padding: "10px", borderColor: "#ced4da" }}
                                                    value={useAddressDetailsTemp?.city}
                                                    onChange={handleChange}
                                                />
                                                <div
                                                    style={{ zIndex: "99999" }}
                                                    className={`position-absolute bg-white ${selectEvent && useAddressDetailsTemp.city ? "city-field" : "d-none"
                                                        }`}
                                                >
                                                    {cityList
                                                        .filter(
                                                            ({ districtName }) =>
                                                                districtName.indexOf(
                                                                    useAddressDetailsTemp?.city?.toUpperCase()
                                                                ) !== -1
                                                        )
                                                        .map((city, index) => (
                                                            <option
                                                                rows="4"
                                                                cols="50"
                                                                key={index}
                                                                style={{ cursor: "pointer" }}
                                                                value={city.districtName}
                                                                onClick={(e) => {
                                                                    // setBillingDetails((prev) => ({
                                                                    //     ...prev,
                                                                    //     city: e.target.value,
                                                                    // }));
                                                                    setUserAddressDetailsTemp(prevState => ({
                                                                        ...prevState,
                                                                        "city": e.target.value
                                                                    }));
                                                                    setSelectEvent(false);
                                                                    // setShowUpdateBtn(true);
                                                                }}
                                                                className="border p-3 bg-light"
                                                            >
                                                                {city.districtName}
                                                            </option>
                                                        ))}
                                                </div>
                                            </div>
                                            <div className='w-100'>
                                                <label
                                                    htmlFor='pincode'
                                                    className='modal-profile-label mt-2'
                                                    style={{ fontWeight: "400" }}
                                                >
                                                    Pincode
                                                </label>
                                                <input
                                                    type='text'
                                                    className='modal-profile-fields'
                                                    placeholder='pincode'
                                                    name='pincode'
                                                    maxLength={6}
                                                    pattern="[1-9]{6}"
                                                    min={1}
                                                    max={9}
                                                    onKeyPress={(e) => {
                                                        const allowedKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab']; // Allowed keys
                                                        if (!allowedKeys.includes(e.key) && !/^\d$/.test(e.key)) { // Allow only numeric characters
                                                            e.preventDefault();
                                                        }
                                                        const pincode = e.target.value + e.key;
                                                        if (pincode.includes('0000')) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    style={{ padding: "10px", borderColor: "#ced4da" }}
                                                    onChange={handleChange}
                                                    value={useAddressDetailsTemp?.pincode}
                                                />
                                            </div>
                                            <button className="w-100 my-4" onClick={updateAddress} style={{ position: "relative" }}>
                                                Change Address
                                            </button>
                                        </>
                                }

                            </div>
                        </div> : <></>}
                        <div className="address-card-new p-3" style={{ position: "relative" }}>
                            <div className="address-card-new-header mt-1 mb-3 d-flex align-items-center justify-content-start">
                                <h2>This Course Includes</h2>
                            </div>
                            <Row>
                                {
                                    subscriptionDetails[0]?.packInclusions.map((ele, index) => {
                                        return (
                                            <Col md={11} className="mb-1">
                                                <img src={`${process.env.PUBLIC_URL}/assets/course-feature${index + 1}.png`} height="45" width="45" alt="" className="mr-3" />
                                                <span style={{ fontSize: "14px", fontWeight: "500" }}>{ele}</span>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </div>
                        <div className="address-card-new p-3" style={{ position: "relative" }}>
                            <div className="payment-card-new-header px-4 pt-4">
                                <p className="m-0">Basic Fee</p>
                                <p className="m-0">{basePriceTotal.toFixed(2)}</p>
                            </div>
                            <div className="payment-card-new-header px-4 mt-1">
                                <p className="m-0">GST</p>
                                <p className="m-0">{GSTTotal.toFixed(2)}</p>
                            </div>
                            <div className="payment-card-new-header px-4 mt-1">
                                <input
                                    type="text"
                                    autoComplete='off'
                                    id='coupon'
                                    className='modal-profile-fields mt-4'
                                    name='coupon'
                                    placeholder='Coupon'
                                    onChange={handleCouponChange}
                                    value={appliedCoupon}
                                    style={{ padding: "10px", borderColor: "#ced4da" }}
                                />
                                {/* <button className="py-2 ml-1 mt-4" disabled={actualPriceTotal <= 0} onClick={applyCoupon}> */}
                                {
                                    !appliedCoupon ? <></> : <button className="py-2 ml-1 mt-4" onClick={clearApplyCoupon}>
                                        Clear
                                    </button>
                                }
                                <button className="py-2 ml-1 mt-4" onClick={applyCoupon}>
                                    {!isCouponApplied ? "Apply" : "Applied"}
                                </button>
                            </div>
                            {couponValidationError !== "" ? <small className="text-danger px-4 mb-3">{couponValidationError}</small> : couponDetails ? <small className="text-success px-4 mb-3">{couponValidationSuccess}</small> : <></>}
                            <div className="payment-card-new-header px-4 mt-2">
                                <p className="m-0">Coupon Discount</p>
                                <p className="m-0">{couponDetails?.couponDiscountAmt}</p>
                            </div>
                            <hr className="m-2 p-0 bg-secondary" />
                            <div className="payment-card-new-header px-4 mt-1">
                                <p className="m-0"><strong>Total Amount</strong></p>
                                <p className="m-0">{couponDetails ? couponDetails?.totalPayableAmt : actualPriceTotal.toFixed(2)}</p>
                            </div>
                            {/* <button className="w-100 my-4" onClick={handlePayment} disabled={actualPriceTotal <= 0} style={{ position: "relative" }}> */}
                            <button className="w-100 my-4" onClick={handlePayment} style={{ position: "relative" }}>
                                {loader ? <>
                                    <div className="py-3">
                                        <ThreeDots
                                            height="80"
                                            width="80"
                                            color="#fff"
                                            ariaLabel="three-dots-loading"
                                            wrapperStyle={{
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translate(-50%, -50%)",
                                                zIndex: "999",
                                            }}
                                            visible={loader}
                                        />
                                    </div>

                                </> : <>
                                    Proceed to pay
                                </>}

                            </button>
                        </div>

                    </Col>
                </Row >
            </Container >
        </>

    );
};
export default PaymentNew;
