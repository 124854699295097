import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const courseSearchTodo = createAsyncThunk('courseSearch', async (data) => {

    // console.log(data, 'data search')

    try {
        const res = await axios({
            method: 'POST',
            url: process.env.REACT_APP_SNAPSHOT_BASE_URL + `besst-0.0.1-SNAPSHOT/search/filtered_data`,
            data: {
                "course": {
                    "id": data?.course
                },
                "college": {
                    "id": data?.college
                },
                "degree": {
                    "id": data?.degree
                },
                "university": {
                    "id": data?.university
                }
            },
        });
        return res.data; // Return only the serializable data
    } catch (error) {
        throw error.response.data; // Optionally return serializable error data
    }
});


export const extraReducersForCourseSearchThunk = (builder) => {
    builder
        .addCase(courseSearchTodo.pending, (state) => {
            state.isLoading = {
                ...state.isLoading,
                courseSearchLoading: true,
            };
        })
        .addCase(courseSearchTodo.fulfilled, (state, action) => {
            state.isLoading = {
                ...state.isLoading,
                courseSearchLoading: false,
            };
            state.courseSearch = action.payload; // Use the data directly
        })
        .addCase(courseSearchTodo.rejected, (state) => {
            state.isLoading = {
                ...state.isLoading,
                courseSearchLoading: false,
            };
            state.isError = true;
        });
};




