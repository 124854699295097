import "bootstrap/dist/css/bootstrap.min.css";
import { FaSearch } from "react-icons/fa";
import Thumbnail1 from "../../../Assets/images/news-thumbnail1.png";
import Thumbnail2 from "../../../Assets/images/news-thumbnail2.png";
const baseURL = process.env.REACT_APP_BASE_URL;
const News = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  const newsData = [
    {
      title:
        "The Future Of eLearning: Trends And Predictions For 2023 And Beyond",
      date: "NOVEMBER 28, 2023",
      author: " BY BARNANA SARKA",
      content:
        "The Moodle Admin Skills Program follows on from the Moodle Admin Basics Program. It allows you to learn to configure Moodle core features, plugins and certified integrations, with consideration of relevant laws, policies and standards. It focuses on how to develop the best admin practices, such as user management and enrolment, reporting, app configuration and data protection.The Moodle Admin Skills Program follows on from the Moodle.",
      thumbnail: Thumbnail1,
    },
    {
      title:
        "The Future Of eLearning: Trends And Predictions For 2023 And Beyond",
      date: "NOVEMBER 28, 2023",
      author: " BY BARNANA SARKA",
      content:
        "The Moodle Admin Skills Program follows on from the Moodle Admin Basics Program. It allows you to learn to configure Moodle core features, plugins and certified integrations, with consideration of relevant laws, policies and standards. It focuses on how to develop the best admin practices, such as user management and enrolment, reporting, app configuration and data protection.The Moodle Admin Skills Program follows on from the Moodle.",
      thumbnail: null,
    },
    {
      title:
        "The Future Of eLearning: Trends And Predictions For 2023 And Beyond",
      date: "NOVEMBER 28, 2023",
      author: " BY BARNANA SARKA",
      content:
        "The Moodle Admin Skills Program follows on from the Moodle Admin Basics Program. It allows you to learn to configure Moodle core features, plugins and certified integrations, with consideration of relevant laws, policies and standards. It focuses on how to develop the best admin practices, such as user management and enrolment, reporting, app configuration and data protection.The Moodle Admin Skills Program follows on from the Moodle.",
      thumbnail: Thumbnail2,
    },
    {
      title:
        "The Future Of eLearning: Trends And Predictions For 2023 And Beyond",
      date: "NOVEMBER 28, 2023",
      author: " BY BARNANA SARKA",
      content:
        "The Moodle Admin Skills Program follows on from the Moodle Admin Basics Program. It allows you to learn to configure Moodle core features, plugins and certified integrations, with consideration of relevant laws, policies and standards. It focuses on how to develop the best admin practices, such as user management and enrolment, reporting, app configuration and data protection.The Moodle Admin Skills Program follows on from the Moodle.",
      thumbnail: null,
    },
  ];
  return (
    <div className="">
      <div className="Contact_heading container-fluid mb-5">
        <div className="Contact_content">News & Updates</div>
        <div className="Contact_slashContent">Home / News & Updates</div>
      </div>
      <div className="current-affairs-nav" style={{ padding: "1em 8em" }}>
        <form className="current-affair-search" onSubmit={handleSubmit}>
          <FaSearch fill="#999999" />
          <input placeholder="Search"></input>
          <button type="submit" style={{ display: "none" }}></button>
        </form>
      </div>
      <div className="news-page-container">
        {newsData.map((item) => (
          <div className="news-page-card">
            <div className="news-page-1st-card">
              <div>
                <h3 className="news-page-title">{item.title}</h3>
                <div className="news-page-details">
                  <span style={{ color: "#7B1FA2" }}>{item.date}</span>-
                  <span>{item.author}</span>
                </div>
              </div>
              <button className="news-page-button">View More</button>
            </div>

            <p>{item.content}</p>
            {item.thumbnail && <img src={item.thumbnail} alt="thumbnail" />}
          </div>
        ))}
        <button className="load-news">Load More</button>
      </div>

      <div className="testimonials-poster">
        <h2 style={{ textAlign: "center", color: "black", width: "60%" }}>
          Unlock knowledge, unleash potential – reach out to transform your
          learning experience.
        </h2>

        <button
          className="btn btn-primary testimonials-button"
          style={{
            background: "#71269C",
            borderRadius: "4px",
            width: "fit-content",
          }}
          onClick={() => window.location.href = "/contact"}
        >
          Get in Touch
        </button>
      </div>
    </div>
  );
};

export default News;
