import Author from "../../Assets/images/author.png";
import BlogImage from "../../Assets/images/blog-image.png";
import BlogPreviewImage from "../../Assets/images/blog-preview.png";
import { Container, Row, Col, Image, Accordion, Button } from "react-bootstrap";
import axios from "axios";
import Blog from "../../Components/Copy/Blog/Blog";

function Blogs() {
    return (
        <>
            <Blog />
        </>
    )
}

export default Blogs;
