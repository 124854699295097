import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { Container, Col, Row, Card, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaQuoteLeft, FaQuoteRight, FaRegAddressBook } from "react-icons/fa";
import Students from "../../../Assets/images/student.png";
import Students1 from "../../../Assets/images/students1.png";
import Students2 from "../../../Assets/images/students2.png";
import Instructors from "../../../Assets/images/instructor.png";
const About = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const isSmallScreen = window.innerWidth <= 500;
  useEffect(() => {
    const checkLoginStatus = () => {
      setIsLoggedIn(Cookies.get("token") !== undefined);
    };

    checkLoginStatus();
  }, []);

  const handleClick = () => {
    window.location.href = isLoggedIn ? "/student-dashboard" : "/";
  }
  const data = [
    {
      icon: "./assets/Iconss/5.svg",
      number: "578",
      name: "Students",
    },
    // {
    //   icon: "./assets/Iconss/3.svg",
    //   number: "26k",
    //   name: "Certified Instructor",
    // },
    // {
    //   icon: "./assets/Iconss/2.svg",
    //   number: 72,
    //   name: "Country Language",
    // },
    {
      icon: "./assets/Iconss/1.svg",
      number: "99.9%",
      name: "Success Rate",
    },
    // {
    //   icon: "./assets/Iconss/5.svg",
    //   number: 57,
    //   name: "Trusted Companies",
    // },
    {
      icon: "./assets/Iconss/7.svg",
      number: 57,
      name: "Domain Subject",
    },
    {
      icon: "./assets/Iconss/3.svg",
      number: 57,
      name: "General Test",
    },
    {
      icon: "./assets/Iconss/4.svg",
      number: 57,
      name: "Language Paper",
    },
  ];
  return (
    <div className="">
      <div className="Contact_heading container-fluid mb-5">
        <div className="Contact_content">About BESST</div>
        <div className="Contact_slashContent">Home / About</div>
      </div>
      <div style={{ position: "relative" }}>
        <Row className="justify-content-center p-5">
          <Col lg={6} md={12} style={{ padding: "0 40px" }} className="mb-4">
            <div>
              <p className="about_heading">
                What makes BESST unique as a CUET coaching platform?
              </p>
              <p style={{ color: "#7B1FA2" }}>
                BESST: Your Gateway to Expert CUET Coaching
              </p>
              <p style={{ textAlign: "justify" }}>
                BESST (BRAHMAPUTRA EXAM SUCCESS SUPPORT TEAM) stands as your
                premier destination for expert CUET coaching, specializing in
                both CUET(UG) and CUET(PG) preparation. Our seasoned CUET
                educators, strategically positioned nationwide, provide
                personalized guidance through meticulously crafted online
                courses. Tailored for the unique demands of CUET(UG) and
                CUET(PG), our programs offer flexibility, personalized
                attention, and a commitment to your academic success. Choose
                BESST to embark on a CUET preparation journey that goes beyond
                coaching—it's a partnership for excellence.
              </p>
              <p>Recent engagement</p>
              <div className="d-flex justify-content-between">
                <p className="text-end m-0">
                  <span className="stat1">578</span> Students
                </p>
                <p className="text-start m-0">
                  <span className="stat1">70+</span> Courses
                </p>
              </div>
            </div>
          </Col>

          <Col
            style={{ position: "relative" }}
            lg={6}
            md={12}
            className="mb-4 "
          >
            <div
              className="image-container"
              style={{ borderRadius: "20px", overflow: "hidden", width: isSmallScreen ? "100%" : "80%" }}
            >
              <img
                src={`${process.env.PUBLIC_URL}/assets/caroselimg/abt1.jpeg`}
                alt="Image"
                className="img-fluid"
                style={{ width: "100%", height: "auto" }}
              />
            </div>
            <div className="about-stats-card">
              <Col lg={2} md={6} className="text-center mb-4">
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    alignItems: "flex-start",
                  }}
                >
                  <Image
                    src={`${process.env.PUBLIC_URL}/./assets/Iconss/6.svg `}
                    roundedCircle
                    className="user-image"
                  />
                  <div style={{ textAlign: "left" }}>
                    <p className="number" style={{ margin: 0 }}>
                      1713
                    </p>
                    <p
                      className="name"
                      style={{
                        fontSize: "20px",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                        margin: 0,
                      }}
                    >
                      Practice Papers
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={2} md={6} className="text-center mb-4">
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    alignItems: "flex-start",
                  }}
                >
                  <Image
                    src={`${process.env.PUBLIC_URL}/./assets/Iconss/7.svg `}
                    roundedCircle
                    className="user-image"
                  />
                  <div style={{ textAlign: "left" }}>
                    <p className="number" style={{ margin: 0 }}>
                      728
                    </p>
                    <p
                      className="name"
                      style={{
                        fontSize: "20px",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                        margin: 0,
                      }}
                    >
                      Mock Papers
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={2} md={6} className="text-center mb-4">
                <div
                  style={{
                    display: "flex",
                    gap: "12px",
                    alignItems: "flex-start",
                  }}
                >
                  <Image
                    src={`${process.env.PUBLIC_URL}/./assets/Iconss/8.svg `}
                    roundedCircle
                    className="user-image"
                  />
                  <div style={{ textAlign: "left" }}>
                    <p className="number" style={{ margin: 0 }}>
                      59470
                    </p>
                    <p
                      className="name"
                      style={{
                        fontSize: "20px",
                        whiteSpace: "nowrap",
                        fontWeight: "bold",
                        margin: 0,
                      }}
                    >
                      Total MCQ
                    </p>
                  </div>
                </div>
              </Col>
            </div>
          </Col>
        </Row>

        <Row
          className="justify-content-center"
          style={{ padding: "0 10% 0 10%" }}
        >
          <Col lg={12} style={{ padding: "2rem 1rem" }} className="mb-4 text-center">
            <h2
              className="mb-2 font-weight-bold"
              style={{ fontSize: "2rem", color: "black" }}
            >
              How does BESST CUET Coaching serve as your roadmap to success?
            </h2>
            <p className="text-center mb-4">
              At BESST (BRAHMAPUTRA EXAM SUCCESS SUPPORT TEAM), our seasoned
              CUET mentors, strategically located nationwide, bring a wealth of
              expertise to your CUET (UG) and CUET(PG) preparation journey.
              Dedicated to fostering an environment where students not only
              learn but thrive, as we go beyond traditional coaching. With BESST
              CUET coaching, your preparation transcends routine studying; it
              becomes a dynamic exploration, empowering you for success in both
              CUET (UG) and CUET(PG). Trust us to be more than just educators –
              we're your dedicated partners, committed to guiding you towards
              excellence in the challenging landscape of CUET.
            </p>
            <div className="image-container d-flex justify-content-center">
              {/* <div className="image-wrapper">
                <img
                  src={Instructors}
                  alt="Image 1"
                  className="custom-image"
                  style={{
                    opacity: 0.7,
                    fill: "rgba(23, 27, 65, 0.70)",
                  }}
                />
                <div className="abt_section">
                  <h4 style={{ whiteSpace: "nowrap", padding: "1rem" }}>
                    FOR INSTRUCTORS
                  </h4>
                  <button className="about-button">Start A CLASS</button>
                </div>
              </div> */}
              <div className="image-wrapper">
                <img
                  src={Students}
                  alt="Image 2"
                  className="custom-image"
                  style={{ opacity: 0.7, fill: "rgba(23, 27, 65, 0.70)" }}
                />
                <div className="abt_section">
                  <h4 style={{ whiteSpace: "nowrap", padding: "1rem" }}>
                    FOR STUDENTS
                  </h4>
                  <button className="about-button" onClick={handleClick}>Start Course</button>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        <Row
          className="justify-content-center p-5"
          style={{ backgroundColor: "rgba(123, 31, 162, 0.05)" }}
        >
          <Col lg={6} md={12} className="mb-4">
            <div className="Row_heading">
              <img
                src={`${process.env.PUBLIC_URL}/assets/caroselimg/left-1.png`}
                alt="Left Image"
                className="img-fluid mx-auto"
              />
            </div>
          </Col>

          <Col lg={6} md={12} className="mb-4 d-flex align-items-center">
            <div className="px-3">
              <h2
                className="mb-3"
                style={{ color: "black", textAlign: "left" }}
              >
                <span style={{ color: "#7B1FA2" }}> Our Mission:</span>{" "}
                Empowering <br></br>Students for CUET Success
              </h2>
              <p style={{ textAlign: "justify" }}>
                Our mission is crystal clear: to equip students with the
                knowledge and skills required to excel in both CUET (UG) and
                CUET(PG). Whether you're gearing up for CUET or similar
                challenging examinations, BESST CUET coaching is your go-to
                platform for the resources and guidance needed to achieve your
                academic goals
              </p>
            </div>
          </Col>
        </Row>

        <div className="about-us-preview">
          <Col lg={4} md={8} sm={10} className="mb-4">
            <div>
              <h2
                style={{
                  color: "black",
                  fontWeight: "600",
                  textAlign: "left",
                  marginBottom: "12px",
                }}
              >
                Your Success, Our Commitment
              </h2>
              <p style={{ textAlign: "justify" }}>
                At BESST CUET coaching, your success is our primary focus. We
                understand the challenges posed by the competitive CUET exam
                landscape, and we are dedicated to equipping you with the skills
                and knowledge required to overcome them.
                <br />
                <br />
                Join us at BESST CUET coaching, and let's embark on this
                educational journey together. Your CUET success dreams are
                within reach, and we are here to help you turn them into
                reality.
              </p>
            </div>
          </Col>
          <div className="images-wrapper">
            <img
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
              }}
              src={Students1}
              alt="students"
            />
            <div className="floating-image">
              <img
                style={{
                  border: "1em white solid",
                  borderRadius: "10px",
                  maxHeight: "100%",
                  maxWidth: "100%",
                }}
                src={Students2}
                alt="students"
              />
            </div>
          </div>
          {/* <Col lg={12} md={12} sm={12} className="mb-4">
    <Row className="justify-content-center">
      {[...Array(5)].map((_, index) => (
        <Col key={index} sm={6} md={4} lg={3} className="mb-3">
          <div className="data-container">
            <FaRegAddressBook className="data-icon" />
            <p className="data-number">123</p>
            <button className="btn btn-primary data-button">Name</button>
          </div>
        </Col>
      ))}
    </Row>
  </Col> */}
        </div>
        <Row className="p-5 justify-content-center">
          {data.map((item, index) => (
            <Col key={index} lg={2} md={6} className="text-center m-4 py-4">
              <div
                style={{
                  display: "flex",
                  gap: "12px",
                  alignItems: "flex-start",
                }}
              >
                <Image
                  src={`${process.env.PUBLIC_URL}/${item.icon}`}
                  roundedCircle
                  className="user-image"
                />
                <div style={{ textAlign: "left" }}>
                  <p
                    className="number"
                    style={{ fontSize: "26px", fontWeight: "bold", margin: 0 }}
                  >
                    {item.number}
                  </p>
                  <p className="name" style={{ margin: 0 }}>
                    {item.name}
                  </p>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

export default About;
