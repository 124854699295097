import * as Yup from "yup";

export const FormSchema = Yup.object({

    first_name: Yup.string().required("Enter Name"),
    last_name: Yup.string().required("Enter Last Name"),
    email: Yup.string().email().required("Enter Email"),
    phone: Yup.number().required("Enter Phone"),
    message: Yup.string().required("Enter Message"),

});