import React from "react";
import { Button, Row, Col, Card, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaQuoteLeft, FaQuoteRight, FaRegAddressBook } from "react-icons/fa";

const Testimonials = () => {
  return (
    // <div className="">
    //   <div className="Contact_heading container-fluid mb-5">
    //     <div className="Contact_content">Testimonial</div>
    //     <div className="Contact_slashContent">Home / Testimonial</div>
    //   </div>
    //   <h1 className="testimonials-heading">What Our Customers Are Saying</h1>
    //   <div className="testimonials-container">
    //     <div className="testimonials-col">
    //       <div className="testimonials-card">
    // <div className="testimonials-section">
    //   <FaQuoteLeft style={{ fill: "#7B1FA2" }} />
    //   <p style={{ textAlign: "left" }}>
    //     I have taken online class with Kaushik Sir , he was the perfect
    //     trainer for me I could have imagined.He has been very patient
    //     with me and always very supportive. He starts from basics and
    //     gradually gets into advanced topics.The study material that sir
    //     provided was very useful student with any level of knowledge can
    //     start, learn and excel .Its practice paper and mock test papers
    //     helped me a lot and the materials of Cuet and UG are very
    //     informative . Thank you sir for your support 😎
    //   </p>
    // </div>

    // <p className="testimonials-section" style={{ margin: "0" }}>
    //   <div className="testimonial-name">Parashmoni Boruah</div>
    //   Course: CUET (UG) Live
    // </p>
    //       </div>
    //       <div className="testimonials-card">
    // <div className="testimonials-section">
    //   <FaQuoteLeft style={{ fill: "#7B1FA2" }} />
    //   <p style={{ textAlign: "left" }}>
    //     This web portal is amazing and very useful
    //   </p>
    // </div>

    // <p className="testimonials-section" style={{ margin: "0" }}>
    //   <div className="testimonial-name">Preeti Patel</div>
    //   Course: CUET (UG) Live
    // </p>
    //       </div>
    //     </div>
    //     <div className="testimonials-col">
    //       <div className="testimonials-card">
    // <div className="testimonials-section">
    //   <FaQuoteLeft style={{ fill: "#7B1FA2" }} />
    //   <p style={{ textAlign: "left" }}>
    //     Really good content in their webportal for cuet (ug)
    //   </p>
    // </div>

    // <p className="testimonials-section" style={{ margin: "0" }}>
    //   <div className="testimonial-name">Raja Dey</div>
    //   Course: CUET (UG) Live
    // </p>
    //       </div>
    //       <div className="testimonials-card">
    // <div className="testimonials-section">
    //   <FaQuoteLeft style={{ fill: "#7B1FA2" }} />
    //   <p style={{ textAlign: "left" }}>
    //     This web portal is very much useful for any kind of competitive
    //     exam preparation.. Specially for cuet.. And also provides live
    //     classes...mock tests are also available.. There are lots of
    //     questions for every subject for regular practice.. Thank you
    //     BESST for providing best materials..
    //   </p>
    // </div>

    // <p className="testimonials-section" style={{ margin: "0" }}>
    //   <div className="testimonial-name">Chumi</div>
    //   Course: CUET (UG) Live
    // </p>
    //       </div>
    //     </div>
    //     <div className="testimonials-col">
    //       <div className="testimonials-card">
    // <div className="testimonials-section">
    //   <FaQuoteLeft style={{ fill: "#7B1FA2" }} />
    //   <p style={{ textAlign: "left" }}>
    //     The portal is amazing and useful
    //   </p>
    // </div>

    // <p className="testimonials-section" style={{ margin: "0" }}>
    //   <div className="testimonial-name">Sumit Jain</div>
    //   Course: CUET (UG) Live
    // </p>
    //       </div>
    //       <div className="testimonials-card">
    // <div className="testimonials-section">
    //   <FaQuoteLeft style={{ fill: "#7B1FA2" }} />
    //   <p style={{ textAlign: "left" }}>
    //     The material provided is quite helpful.A great
    //     initiative.Wishing more success and growth to BESST!
    //   </p>
    // </div>

    // <p className="testimonials-section" style={{ margin: "0" }}>
    //   <div className="testimonial-name">Krishna Maheshwari</div>
    //   Course: CUET (UG) Live
    // </p>
    //       </div>
    //       <div className="testimonials-card">
    //         <div className="testimonials-section">
    //           <FaQuoteLeft style={{ fill: "#7B1FA2" }} />
    //           <p style={{ textAlign: "left" }}>Really helpful</p>
    //         </div>

    //         <p className="testimonials-section" style={{ margin: "0" }}>
    //           <div className="testimonial-name">Rahim Sutradhar</div>
    //           Course: CUET (UG) Live
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="testimonials-poster">
    //     <h2 style={{ textAlign: "center", color: "black", width: "60%" }}>
    //       Unlock knowledge, unleash potential – reach out to transform your
    //       learning experience.
    //     </h2>

    //     <button
    //       className="btn btn-primary testimonials-button"
    //       style={{
    //         background: "#71269C",
    //         borderRadius: "4px",
    //         width: "fit-content",
    //       }}
    //     >
    //       Get in Touch
    //     </button>
    //   </div>
    // </div>

    <div className="">
      <div className="Contact_heading container-fluid mb-5">
        <div className="Contact_content">Testimonial</div>
        <div className="Contact_slashContent">Home / Testimonial</div>
      </div>
      <h1 className="testimonials-heading" style={{ margin: "60px 0 60px 0" }}>What Our Customers Are Saying</h1>
      <div class="card-columns p-2">
        <div class="card" style={{ borderTop: "3px solid #542567" }}>
          <div class="card-body">
            <div className="testimonials-section">
              <FaQuoteLeft style={{ fill: "#7B1FA2" }} />
              <p style={{ textAlign: "left" }}>
                I have taken online class with Kaushik Sir , he was the perfect
                trainer for me I could have imagined.He has been very patient
                with me and always very supportive. He starts from basics and
                gradually gets into advanced topics.The study material that sir
                provided was very useful student with any level of knowledge can
                start, learn and excel .Its practice paper and mock test papers
                helped me a lot and the materials of Cuet and UG are very
                informative . Thank you sir for your support 😎
              </p>
            </div>
            <p className="testimonials-section" style={{ margin: "0" }}>
              <div className="testimonial-name">Parashmoni Boruah</div>
              Course: CUET (UG) Live
            </p>
          </div>
        </div>
        <div class="card" style={{ borderTop: "3px solid #542567" }}>
          <div class="card-body">
            <div className="testimonials-section">
              <FaQuoteLeft style={{ fill: "#7B1FA2" }} />
              <p style={{ textAlign: "left" }}>
                This web portal is amazing and very useful
              </p>
            </div>

            <p className="testimonials-section" style={{ margin: "0" }}>
              <div className="testimonial-name">Preeti Patel</div>
              Course: CUET (UG) Live
            </p>
          </div>
        </div>
        <div class="card" style={{ borderTop: "3px solid #542567" }}>
          <div class="card-body">
            <div className="testimonials-section">
              <FaQuoteLeft style={{ fill: "#7B1FA2" }} />
              <p style={{ textAlign: "left" }}>
                This web portal is very much useful for any kind of competitive
                exam preparation.. Specially for cuet.. And also provides live
                classes...mock tests are also available.. There are lots of
                questions for every subject for regular practice.. Thank you
                BESST for providing best materials..
              </p>
            </div>

            <p className="testimonials-section" style={{ margin: "0" }}>
              <div className="testimonial-name">Chumi</div>
              Course: CUET (UG) Live
            </p>
          </div>
        </div>
        <div class="card" style={{ borderTop: "3px solid #542567" }}>
          <div class="card-body">
            <div className="testimonials-section">
              <FaQuoteLeft style={{ fill: "#7B1FA2" }} />
              <p style={{ textAlign: "left" }}>
                The portal is amazing and useful
              </p>
            </div>

            <p className="testimonials-section" style={{ margin: "0" }}>
              <div className="testimonial-name">Sumit Jain</div>
              Course: CUET (UG) Live
            </p>
          </div>
        </div>
        <div class="card" style={{ borderTop: "3px solid #542567" }}>
          <div class="card-body">
            <div className="testimonials-section">
              <FaQuoteLeft style={{ fill: "#7B1FA2" }} />
              <p style={{ textAlign: "left" }}>
                Really good content in their webportal for cuet (ug)
              </p>
            </div>

            <p className="testimonials-section" style={{ margin: "0" }}>
              <div className="testimonial-name">Raja Dey</div>
              Course: CUET (UG) Live
            </p>
          </div>
        </div>
        <div class="card" style={{ borderTop: "3px solid #542567" }}>
          <div class="card-body">
            <div className="testimonials-section">
              <FaQuoteLeft style={{ fill: "#7B1FA2" }} />
              <p style={{ textAlign: "left" }}>
                This web portal is very much useful for any kind of competitive
                exam preparation.. Specially for cuet.. And also provides live
                classes...mock tests are also available.. There are lots of
                questions for every subject for regular practice.. Thank you
                BESST for providing best materials..
              </p>
            </div>

            <p className="testimonials-section" style={{ margin: "0" }}>
              <div className="testimonial-name">Chumi</div>
              Course: CUET (UG) Live
            </p>
          </div>
        </div>
        <div class="card" style={{ borderTop: "3px solid #542567" }}>
          <div class="card-body">
            <div className="testimonials-section">
              <FaQuoteLeft style={{ fill: "#7B1FA2" }} />
              <p style={{ textAlign: "left" }}>
                The material provided is quite helpful.A great
                initiative.Wishing more success and growth to BESST!
              </p>
            </div>

            <p className="testimonials-section" style={{ margin: "0" }}>
              <div className="testimonial-name">Krishna Maheshwari</div>
              Course: CUET (UG) Live
            </p>
          </div>
        </div>
      </div>
      <div className="testimonials-poster">
        <h2 style={{ textAlign: "center", color: "black", width: "60%" }}>
          Unlock knowledge, unleash potential – reach out to transform your
          learning experience.
        </h2>

        <button
          className="btn btn-primary testimonials-button"
          style={{
            background: "#71269C",
            borderRadius: "4px",
            width: "fit-content",
          }}

          onClick={() => window.location.href = "/contact"}
        >
          Get in Touch
        </button>
      </div>
    </div>
  );
};

export default Testimonials;
