import React from 'react'
import { useFormik } from 'formik'
import { Button, Form, Modal, } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
// import { ctetFormTodo } from '../../../Redux/Thunks/Post/PostCTETFormThunk'
import Swal from "sweetalert2";
import { FormSchema } from './ValidationSchema.js'

export default function AdsFormModel(props) {

    const dispatch = useDispatch()
    // const navigate = useNavigate()
    const { show, setShow } = props

    const initialValues = {
        first_name: "",
        email: "",
        phone: "",
    }

    const { values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValues,
        validationSchema: FormSchema,

        onSubmit: (values, action) => {
            // console.log(values)
            // dispatch(ctetFormTodo({ 'values': values })).then((res) => add_res(res.payload, action))
        }
    })

    const add_res = (res, action) => {
        if (res?.status == 201) {
            action.resetForm()
            Swal.fire({
                icon: 'success',
                title: 'Success',
                text: 'Thankyou for Enroll We will get back to you shortly',
                position: 'center',
                showConfirmButton: false,
                timer: 1500
            });
        } else if (res?.status == 400) {
            Swal.fire({
                icon: 'error',
                title: '400 BAD REQUEST',
                text: 'The request could not be understood by the server.',
                position: 'center',
                showConfirmButton: true
            });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Something went wrong',
                position: 'center',
                showConfirmButton: true
            });
        }
    }

    return (
        <>
            <Modal
                size="lg"
                show={show}
                onHide={() => setShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg" style={{ color: '#5a1e7d' }}>
                        <div style={{ fontWeight: '600', fontSize: '25px' }}>
                            Form
                        </div>
                    </Modal.Title>
                </Modal.Header>

                <Form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
                    <Modal.Body>
                        <div className='row'>

                            <div className="col-md-12 mt-2 mb-2">
                                <Form.Control
                                    type="text"
                                    size="sm"
                                    placeholder={'Enter Name'}
                                    className='form_input'
                                    name={'first_name'}
                                    value={values.first_name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}

                                />
                                {errors.first_name && touched.first_name ? (<span className='text-danger p-1' style={{ fontSize: '15px' }} >{errors.first_name}</span>) : null}
                            </div>


                            <div className="col-md-12 mt-2 mb-2">
                                <Form.Control
                                    type="email"
                                    size="sm"
                                    placeholder={'Enter Email'}
                                    className='form_input'
                                    name={'email'}
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.email && touched.email ? (<span className='text-danger p-1' style={{ fontSize: '15px' }} >{errors.email}</span>) : null}
                            </div>

                            <div className="col-md-12 mt-2 mb-2">
                                <Form.Control
                                    type="number"
                                    size="sm"
                                    placeholder={'Enter Phone'}
                                    className='form_input'
                                    name={'phone'}
                                    value={values.phone}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                                {errors.phone && touched.phone ? (<span className='text-danger p-1' style={{ fontSize: '15px' }} >{errors.phone}</span>) : null}
                            </div>

                        </div>

                    </Modal.Body>

                    <Modal.Footer>
                        <Button style={{ fontSize: '12px' }} type='submit' size='sm' variant="primary" className="reg_btn ms-3 mb-2 mt-2" onClick={() => setShow(false)} >Submit</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}
