import React from 'react';

const HighlightSearchText = ({ searchText, text }) => {
    if (!searchText || !text || typeof text !== 'string') {
        return text;
    }

    console.log(searchText, text)
    const regex = new RegExp(`(${searchText})`, 'gi');
    console.log("asdhgakjdhajkdhakjhdakjd", text);
    const parts = text.split(regex);
    console.log(parts);
    return (
        <>
            {parts.map((part, index) =>
                regex.test(part) ? (
                    <span key={index} style={{ backgroundColor: 'yellow' }}>
                        {part}
                    </span>
                ) : (
                    part
                )
            )}
        </>
    );
};

export default HighlightSearchText;