import Author from "../../Assets/images/author.png";
import BlogImage from "../../Assets/images/blog-image.png";
import BlogPreviewImage from "../../Assets/images/blog-preview.png";
import { Container, Row, Col, Image, Accordion, Button } from "react-bootstrap";
import { seoPageRoutes } from "../../RouteConstants";


function ServiceTwo() {
    const isSmallScreen = window.innerWidth <= 500;
    const handleClick = () => {
        console.log(seoPageRoutes.bestOnlinePlatform);
        window.location.href = seoPageRoutes.bestOnlinePlatformCopy;
    }
    return (
        <>
            <div className="blog-bg container-fluid mb-5">
                <div className="Contact_content">Services</div>
                <div className="Contact_slashContent">Home / Services / Igniting Academic Triumph: The Potential of Downloadable Study Materials</div>
            </div>
            {isSmallScreen ? (
                <Row>
                    <Col md={8} sm={12} xs={12}>
                        <div className="p-4">
                            <div>
                                <h1>Igniting Academic Triumph: The Potential of Downloadable Study Materials</h1>
                            </div>
                            <div className="my-4">
                                <img src={`${process.env.PUBLIC_URL}/assets/testimonials/gunjan.svg`} className="author-img" alt="author" />
                                <div className="author-name">By Chinmoy Mohan</div>
                                <div className="text-secondary"><small>Published on November 14,2023</small></div>
                                <div className="text-secondary"><small>2min read</small></div>
                            </div>
                            <img src={BlogImage} alt="author" />
                            <p style={{ textAlign: "justify" }}>
                                In the digital age, education has transcended traditional boundaries, and so has the way we prepare
                                for exams. Downloadable study materials have emerged as a cornerstone of efficient exam
                                preparation, offering students unparalleled accessibility, flexibility, and a wealth of resources at
                                their fingertips. At BESST, we recognize the transformative potential of downloadable study
                                materials, and we have tailored our offerings to empower students on their academic journey
                            </p>
                            <p style={{ textAlign: "justify" }}>
                                Gone are the days of lugging around heavy textbooks and voluminous notes. Downloadable study
                                materials redefine the landscape of exam preparation by providing instant access to comprehensive
                                resources. Students can seamlessly integrate these materials into their study routine, enabling a
                                more dynamic and personalized learning experience. Whether it's revisiting key concepts, practicing
                                sample questions, or delving into advanced topics, downloadable study materials offer a versatile
                                approach to learning.
                            </p>
                            <h1 className="my-1"> BESST: Your Gateway to Exam Excellence</h1>
                            <p style={{ textAlign: "justify" }}>
                                At BESST, we understand the unique challenges faced by students preparing for entrance exams,
                                particularly those aspiring for CUET(UG) and CUET(PG). Our portal is meticulously designed to
                                cater to the needs of CUET students, offering a diverse range of downloadable study materials
                                crafted by subject-matter experts.
                            </p>
                            <h1 className="my-1">Benefits of BESST's Downloadable Study Materials for CUET Exams</h1>
                            <h1>1. Comprehensive Coverage</h1>
                            <p style={{ textAlign: "justify" }}>
                                Our study materials cover the entire spectrum of CUET syllabi,
                                ensuring that students are well-equipped with the knowledge required to excel in the exams.
                            </p>
                            <h1>2. Exam-Focused Content</h1>
                            <p style={{ textAlign: "justify" }}>
                                Tailored to the specific patterns of CUET(UG) and CUET(PG), our
                                downloadable study materials emphasize key areas and provide insights into the exam structure,
                                fostering strategic preparation.
                            </p>
                            <h1>3. Accessible Anytime, Anywhere</h1>
                            <p style={{ textAlign: "justify" }}>
                                With our downloadable resources, students have the flexibility
                                to study at their own pace and convenience, eliminating the constraints of time and location.
                            </p>
                            <h1 className="my-1">Realizing Success: Testimonials from Previous Years</h1>
                            <p style={{ textAlign: "justify" }}>
                                Our commitment to excellence is reflected in the success stories of students who utilized BESST's
                                downloadable study materials. Many have not only aced their exams but have also praised the
                                clarity, relevance, and effectiveness of our resources in simplifying complex concepts.
                            </p>
                            <h1 className="my-1">Register Now on BESST and Elevate Your Preparation</h1>
                            <p style={{ textAlign: "justify" }}>
                                As you embark on your academic journey, let BESST be your guiding light. Register now to avail
                                yourself of our exclusive downloadable study materials designed to streamline your CUET(UG) and
                                CUET(PG) preparation. Join the community of successful students who have harnessed the power
                                of BESST and experienced the difference in their academic endeavors.
                            </p>
                            <p style={{ textAlign: "justify" }}>
                                Register on BESST today and gain access to meticulously curated downloadable study materials.
                                Elevate your preparation for CUET(UG) and CUET(PG) with the trusted resources that have
                                propelled previous year's students to success.
                            </p>
                        </div>
                    </Col>
                    <Col md={4} sm={12} xs={12}>
                        <div className="p-4">
                            <h4>RELATED SERVICES</h4>
                            <div className="blog-preview-container">
                                <div className="blog-preview">
                                    <img
                                        src={BlogPreviewImage}
                                        className="blog-preview-image"
                                        alt="thumbnail"
                                    />
                                    <div className="">
                                        <h5 className="blog-date">November 14, 2023</h5>
                                        <h5 className="blog-preview-title" onClick={() => window.location.href = "/cuet-mock-test"} style={{ cursor: "pointer" }}>
                                            Optimize Your Preparation: Practice CUET Mock Tests with Us
                                        </h5>
                                    </div>
                                </div>
                                <div className="blog-preview">
                                    <img
                                        src={BlogPreviewImage}
                                        className="blog-preview-image"
                                        alt="thumbnail"
                                    />
                                    <div className="">
                                        <h5 className="blog-date">November 14, 2023</h5>
                                        <h5 className="blog-preview-title" onClick={() => window.location.href = "/download-study-material"} style={{ cursor: "pointer" }}>
                                            Igniting Academic Triumph: The Potential of Downloadable Study Materials
                                        </h5>
                                    </div>
                                </div>
                                <div className="blog-preview">
                                    <img
                                        src={BlogPreviewImage}
                                        className="blog-preview-image"
                                        alt="thumbnail"
                                    />
                                    <div className="">
                                        <h5 className="blog-date">November 14, 2023</h5>
                                        <h5 className="blog-preview-title" onClick={() => window.location.href = "/counseling-mock-registration"} style={{ cursor: "pointer" }}>
                                            Navigating Success: Counselling and Mock Registration for Central University
                                            Admissions with BESST.in
                                        </h5>
                                    </div>
                                </div>
                                <div className="blog-preview">
                                    <img
                                        src={BlogPreviewImage}
                                        className="blog-preview-image"
                                        alt="thumbnail"
                                    />
                                    <div className="">
                                        <h5 className="blog-date">November 14, 2023</h5>
                                        <h5 className="blog-preview-title" onClick={() => window.location.href = "/previous-year-question-bank"} style={{ cursor: "pointer" }}>
                                            Exploring Triumph: Harnessing the Strength of BESST's Previous Year
                                            Question Bank
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            ) :
                (<div className="blog-container">
                    <div className="blog-content">
                        <div>
                            <h1>Igniting Academic Triumph: The Potential of Downloadable Study Materials</h1>
                        </div>
                        <div className="author">
                            <img src={`${process.env.PUBLIC_URL}/assets/testimonials/gunjan.svg`} className="author-img" alt="author" />
                            <div className="author-name">By Chinmoy Mohan</div> |
                            <div>Published on November 14,2023</div> | <div>2min read</div>
                        </div>
                        <img src={BlogImage} alt="author" />
                        <p style={{ textAlign: "justify" }}>
                            In the digital age, education has transcended traditional boundaries, and so has the way we prepare
                            for exams. Downloadable study materials have emerged as a cornerstone of efficient exam
                            preparation, offering students unparalleled accessibility, flexibility, and a wealth of resources at
                            their fingertips. At BESST, we recognize the transformative potential of downloadable study
                            materials, and we have tailored our offerings to empower students on their academic journey
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            Gone are the days of lugging around heavy textbooks and voluminous notes. Downloadable study
                            materials redefine the landscape of exam preparation by providing instant access to comprehensive
                            resources. Students can seamlessly integrate these materials into their study routine, enabling a
                            more dynamic and personalized learning experience. Whether it's revisiting key concepts, practicing
                            sample questions, or delving into advanced topics, downloadable study materials offer a versatile
                            approach to learning.
                        </p>
                        <h1 className="my-1"> BESST: Your Gateway to Exam Excellence</h1>
                        <p style={{ textAlign: "justify" }}>
                            At BESST, we understand the unique challenges faced by students preparing for entrance exams,
                            particularly those aspiring for CUET(UG) and CUET(PG). Our portal is meticulously designed to
                            cater to the needs of CUET students, offering a diverse range of downloadable study materials
                            crafted by subject-matter experts.
                        </p>
                        <h1 className="my-1">Benefits of BESST's Downloadable Study Materials for CUET Exams</h1>
                        <h1>1. Comprehensive Coverage</h1>
                        <p style={{ textAlign: "justify" }}>
                            Our study materials cover the entire spectrum of CUET syllabi,
                            ensuring that students are well-equipped with the knowledge required to excel in the exams.
                        </p>
                        <h1>2. Exam-Focused Content</h1>
                        <p style={{ textAlign: "justify" }}>
                            Tailored to the specific patterns of CUET(UG) and CUET(PG), our
                            downloadable study materials emphasize key areas and provide insights into the exam structure,
                            fostering strategic preparation.
                        </p>
                        <h1>3. Accessible Anytime, Anywhere</h1>
                        <p style={{ textAlign: "justify" }}>
                            With our downloadable resources, students have the flexibility
                            to study at their own pace and convenience, eliminating the constraints of time and location.
                        </p>
                        <h1 className="my-1">Realizing Success: Testimonials from Previous Years</h1>
                        <p style={{ textAlign: "justify" }}>
                            Our commitment to excellence is reflected in the success stories of students who utilized BESST's
                            downloadable study materials. Many have not only aced their exams but have also praised the
                            clarity, relevance, and effectiveness of our resources in simplifying complex concepts.
                        </p>
                        <h1 className="my-1">Register Now on BESST and Elevate Your Preparation</h1>
                        <p style={{ textAlign: "justify" }}>
                            As you embark on your academic journey, let BESST be your guiding light. Register now to avail
                            yourself of our exclusive downloadable study materials designed to streamline your CUET(UG) and
                            CUET(PG) preparation. Join the community of successful students who have harnessed the power
                            of BESST and experienced the difference in their academic endeavors.
                        </p>
                        <p style={{ textAlign: "justify" }}>
                            Register on BESST today and gain access to meticulously curated downloadable study materials.
                            Elevate your preparation for CUET(UG) and CUET(PG) with the trusted resources that have
                            propelled previous year's students to success.
                        </p>
                    </div>

                    <div className="right-pane">
                        <h4>RELATED SERVICES</h4>
                        <div className="blog-preview-container">
                            <div className="blog-preview">
                                <img
                                    src={BlogPreviewImage}
                                    className="blog-preview-image"
                                    alt="thumbnail"
                                />
                                <div className="">
                                    <h5 className="blog-date">November 14, 2023</h5>
                                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/cuet-mock-test"} style={{ cursor: "pointer" }}>
                                        Optimize Your Preparation: Practice CUET Mock Tests with Us
                                    </h5>
                                </div>
                            </div>
                            <div className="blog-preview">
                                <img
                                    src={BlogPreviewImage}
                                    className="blog-preview-image"
                                    alt="thumbnail"
                                />
                                <div className="">
                                    <h5 className="blog-date">November 14, 2023</h5>
                                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/download-study-material"} style={{ cursor: "pointer" }}>
                                        Igniting Academic Triumph: The Potential of Downloadable Study Materials
                                    </h5>
                                </div>
                            </div>
                            <div className="blog-preview">
                                <img
                                    src={BlogPreviewImage}
                                    className="blog-preview-image"
                                    alt="thumbnail"
                                />
                                <div className="">
                                    <h5 className="blog-date">November 14, 2023</h5>
                                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/counseling-mock-registration"} style={{ cursor: "pointer" }}>
                                        Navigating Success: Counselling and Mock Registration for Central University
                                        Admissions with BESST.in
                                    </h5>
                                </div>
                            </div>
                            <div className="blog-preview">
                                <img
                                    src={BlogPreviewImage}
                                    className="blog-preview-image"
                                    alt="thumbnail"
                                />
                                <div className="">
                                    <h5 className="blog-date">November 14, 2023</h5>
                                    <h5 className="blog-preview-title" onClick={() => window.location.href = "/previous-year-question-bank"} style={{ cursor: "pointer" }}>
                                        Exploring Triumph: Harnessing the Strength of BESST's Previous Year
                                        Question Bank
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)
            }
            <div className="blog-bg container-fluid d-flex">
                <Container>
                    <Row>
                        <Col sm={8} md={9} xs={12}>
                            <p className="abso_p">
                                Don't miss this opportunity to enhance your skills and
                                confidence, take free mock tests now!
                            </p>
                        </Col>
                        <Col sm={4} md={3} xs={12} className="d-flex align-items-center w-100 justify-content-center">
                            <button type="button" className="sub_button1 btn" onClick={handleClick}>
                                Take Free Test
                            </button>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default ServiceTwo;
