import React from "react";
import { Container, Row, Col, Form, Button, Image } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const Contact = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    subject: "",
    message: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isAnyFieldEmpty = Object.values(formData).some(
      (value) => value.trim() === ""
    );

    if (isAnyFieldEmpty) {
      Swal.fire({
        icon: "error",
        title: "Enter all details.",
        toast: true,
        position: "bottom-right",
        customClass: {
          popup: "colored-toast",
        },
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
      return;
    }

    try {
      const response = await axios.post(
        `${baseURL}/df/submitFeedback`,
        {
          fullName: formData.fullName,
          email: formData.email,
          subject: formData.subject,
          message: formData.message,
          otherSubject: "",
          courseId: Cookies.get("preferredCourseId") || "1",
        },
        {
          headers: {
            "Acces-Control-Allow-Origin": "*",
            Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
          },
        }
      );

      if (response.data.ResultCode == "200") {
        Swal.fire({
          icon: "success",
          title: "Feedback Submitted",
          toast: true,
          position: "bottom-right",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        setTimeout(() => {
          window.location.href = "/";
        }, 3000)

      } else {
        console.log(response.data);
        console.error("Authentication failed");
      }
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error. Please try again!",
        toast: true,
        position: "bottom-right",
        customClass: {
          popup: "colored-toast",
        },
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    }
  };

  const phoneNumber = process.env.REACT_APP_PHONE_NUMBER;
  const defaultMessage = process.env.REACT_APP_MESSAGE;

  const handleWhatsapp = () => {
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${defaultMessage}`;
    window.open(whatsappLink, '_blank');
  }

  const handleEmail = () => {
    window.open(`mailto:info@besst.in?subject=Request%20for%20Reconsideration%20of%20Salary%20Revision&body=Dear%20[Recipient's%20Name],%0A%0AI%20hope%20this%20email%20finds%20you%20well.%20I%20would%20like%20to%20express%20my%20gratitude%20for%20the%20recent%20salary%20revision%3B%20however%2C%20after%20careful%20consideration%2C%20I%20find%20myself%20compelled%20to%20discuss%20my%20concerns%20regarding%20the%20adjustment.%0A%0AI%20want%20to%20emphasize%20my%20dedication%20to%20the%20team%20and%20my%20enthusiasm%20for%20contributing%20to%20the%20continued%20success%20of%20TechAhead.%20The%20company%20has%20provided%20me%20with%20invaluable%20experiences%2C%20and%20I%20am%20committed%20to%20playing%20a%20significant%20role%20in%20its%20growth.%0A%0AHaving%20received%20offers%20from%20other%20firms%2C%20it%20became%20apparent%20that%20the%20recent%20salary%20revision%20falls%20short%20of%20my%20expectations.%20While%20I%20deeply%20appreciate%20the%20recognition%20of%20my%20contributions%2C%20the%20current%20adjustment%20doesn't%20align%20with%20the%20industry%20standards%20and%20the%20offers%20I've%20received%20from%20elsewhere.%0A%0AI%20believe%20in%20open%20communication%20and%20transparency%2C%20and%20it%20is%20with%20this%20spirit%20that%20I%20am%20reaching%20out%20to%20discuss%20the%20possibility%20of%20a%20reconsideration%20of%20my%20revised%20salary.%20I%20am%20more%20than%20willing%20to%20engage%20in%20a%20constructive%20dialogue%20to%20find%20a%20solution%20that%20aligns%20both%20with%20my%20expectations%20and%20the%20company's%20budgetary%20constraints.%0A%0AI%20look%20forward%20to%20discussing%20this%20matter%20further%20and%20appreciate%20your%20understanding%20and%20consideration.%0A%0AThank%20you%20for%20your%20time.%0A%0ABest%20regards%2C%0A%0A[Your%20Full%20Name]%0A[Your%20Position]%0A[Contact%20Information]`, '_blank');
  }

  return (
    <div className="">
      <div className="Contact_heading container-fluid mb-5">
        <div className="Contact_content">Contact Us</div>
        <div className="Contact_slashContent">Home / Contact</div>
      </div>

      <Container className="mt-5">
        <Row>
          <Col lg={6} className="mb-4">
            <div className="Row_heading">
              Contact Info
              <div className="heading_bar"></div>
              <div
                className="contact_para"
                style={{ padding: "0 64px 36px 0" }}
              >
                We're here to assist you on your educational journey. Have
                questions, feedback, or need support? Feel free to reach out to
                us. Our dedicated team is ready to help.
              </div>
              <div className="contact_location">
                <div className="contact_location_para">
                  <Image
                    src={`${process.env.PUBLIC_URL}/assets/caroselimg/Vector (5).png`}
                    alt="locationLogo"
                    className="contact_location_logo"
                    style={{ height: "24px", marginTop: "12px" }}
                  />
                  <address>
                    Brahmaputra Exam Success Support Team <br></br>Private
                    Limited, 37, 2nd by-lane, B. R. <br></br>Mazumdar Path,
                    Baghorbori, Panjabari,<br></br>
                    Guwahati-781037, Assam
                  </address>
                </div>
              </div>
              <div className="whatsApp_content" onClick={handleWhatsapp} style={{ cursor: "pointer" }}>
                <Image
                  src={`${process.env.PUBLIC_URL}/assets/caroselimg/Clip path group.png`}
                  alt="locationLogo"
                  className="contact_location_logo"
                />
                8822403212
              </div>
              <div className="whatsApp_content" style={{ cursor: "pointer" }}>
                <Image
                  src={`${process.env.PUBLIC_URL}/assets/caroselimg/Vector (3).png`}
                  alt="locationLogo"
                  className="contact_location_logo"
                />
                info@besst.in
              </div>
              <div className="whatsApp_content" onClick={handleEmail} style={{ cursor: "pointer" }}>
                <Image
                  src={`${process.env.PUBLIC_URL}/assets/caroselimg/phone-call-svgrepo-com 1.png`}
                  alt="locationLogo"
                  className="contact_location_logo"
                />
                {phoneNumber}
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="Row_heading">
              Get In Touch
              <div className="heading_bar"></div>
              <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder="Full Name"
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleChange}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Form.Control
                      type="text"
                      placeholder="Subject"
                      name="subject"
                      value={formData.subject}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Message"
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button type="submit" className="send_msg_btn">
                      Send Message
                    </Button>
                  </Col>
                </Row>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="contact_location_map container-fluid p-0 mt-5">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3581.832618781456!2d91.82181131495342!3d26.13699998346788!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x55c6a8e4bda24a24!2zMjbCsDA4JzEzLjIiTiA5McKwNDknMjYuNCJF!5e0!3m2!1sen!2sin!4v1656154630597!5m2!1sen!2sin" width="100%" height="100%" style={{ minHeight: "25rem" }}></iframe>
      </div>
    </div >
  );
};

export default Contact;
