import React from 'react'
import { Accordion, Card, Col, Image, Row } from 'react-bootstrap';
import { useState } from "react";
import './CTET.css'
import { FaCirclePlay } from 'react-icons/fa6';
import FormModel from './FormModel';


export default function Ctet({ type }) {

  const [show, setShow] = useState(false);


  return (
    <>
      <div className=''>
        {/* <div className='ms-0 ms-lg-5 p-1 fs-4 mt-4 mb-3 text-center '>
          <h1 className='text-dark'></h1>
          <p style={{ fontSize: '18px' }}></p>
        </div> */}
        <div className="cuethead">
          <h1>CTET COURSE</h1>
          <p>HOME/CTET COURSE</p>
        </div>

        <div>

          {/* Main Content */}
          <div className="row">

            <h1 style={{ color: '#000', textAlign: 'center' }} className='hone_size mb-5 mt-2'>Best CTET Coaching in Delhi: Unlock Your Teaching Dreams with BESST</h1>

            {/* Introduction to CTET and Coaching in Delhi */}

            <div className="col-md-12">
              <div className='d-inherit d-md-flex' style={{ background: '#f9efff', width: '100%', padding: '50px' }}>
                {/* <div > */}
                <Image
                  // style={{ padding: '50px' }}
                  src={`${process.env.PUBLIC_URL}/assets/cuetimg/course_new.jpg`}
                  className="courseimg img-fluid"
                  rounded
                  alt="CTET"
                />


                <div className="cuetctn ms-0 mt-5 ms-lg-5 mt-lg-0">

                  <h2 className='htwo_size' style={{ color: '#000', textAlign: 'start', background: 'none' }}>Introduction to CTET and Coaching in Delhi</h2>
                  <p style={{ textAlign: "justify", fontSize: '18px' }} className='mt-2 mb-2'>
                    The Central Teacher Eligibility Test (CTET) is a crucial exam for aspiring teachers in India. With the increasing competition, coaching has become essential to crack the exam. Delhi, being the hub of education, offers numerous coaching centers, but choosing the best CTET coaching in Delhi can be daunting. In this article, we will explore the importance of CTET coaching and why BESST stands out as the top CTET coaching in Delhi.
                  </p>
                </div>
                {/* </div> */}
              </div>
            </div>

            {/* Join BESST - The Best CTET Coaching in Delhi */}

            <div className='join_img_ctet'>
              <div className="reg_content_ctet mb-5">
                <div className='row justify-content-between align-items-center'>
                  <div className='col-md-12 d-flex flex-column text-center'>
                    <h2 className='mt-5 htwo_size' style={{ color: '#fff', fontSize: '1.5em' }}>Join BESST - The Best CTET Coaching in Delhi</h2>
                    <p className='mt-5 fs-5'>
                      Don't miss your chance to become a successful teacher. Contact BESST today to learn <br></br>more about our CTET coaching program and take the first step towards <br></br>achieving your dreams.
                    </p>
                  </div>
                  {/* <div className='col-md-12 text-center mb-5 mt-5'>
                    <a href="../BESST PROFILE.pdf" download>
                       <FaCirclePlay size={100} className="fa-circle-play_ctet mt-5" /> 
                    </a>
                  </div> */}
                  <div className='col-md-12 text-center mb-5 mt-5'>
                    {/* <button
                      className="reg_btn"
                      type="button"
                      onClick={() => {
                        window.location.href = "/signup";
                      }}
                    >
                      Register Now
                    </button> */}
                    <button
                      className="reg_btn ms-3"
                      type="button"
                      onClick={() => setShow(true)}
                    >
                      Enroll
                    </button>
                  </div>
                </div>
              </div>
            </div>

            {/* About BESST - Brahmaputra Exam Success Support Team */}

            <div className="col-md-12">
              <div className='d-inherit d-md-flex' style={{ background: '#f9efff', width: '100%', padding: '50px' }}>

                <div className="cuetctn ms-0 me-0 me-sm-5 mb-5 ms-lg-5 mb-lg-0">
                  <h2 style={{ color: '#000', textAlign: 'start', background: 'none' }} className='htwo_size'> About BESST - Brahmaputra Exam Success Support Team</h2>
                  <p style={{ textAlign: "justify", fontSize: '18px' }} className='mt-2 mb-2'>
                    BESST stands out as the top CTET coaching in Delhi, offering a comprehensive program designed to help aspirants succeed. Our team of experienced faculty, extensive curriculum, and proven track record make us the preferred choice. At BESST, we believe in providing personalized attention to each student, helping them overcome their weaknesses and achieve their goals.
                  </p>
                </div>

                {/* <div className='col-md-5'> */}
                <Image
                  src={`${process.env.PUBLIC_URL}/assets/cuetimg/bscdegreecourse-1647858929.jpg`}
                  className="courseimg img-fluid"
                  rounded
                  alt="CTET"
                />
                {/* </div> */}
              </div>
            </div>

            {/* Key Features of BESST's CTET Coaching Program */}

            <div className="container mt-4">

              <Row className="gs-5 ge-5">
                {/* Breadcrumb Card */}
                <Col md={12} className='text-center mt-4 mb-4'>
                  <h3 style={{ color: '#5a1e7d', fontSize: '1.5em' }} className='hthree_size'>Key Features of BESST's CTET Coaching Program</h3>
                </Col>

                <Col md={3} >
                  <Card className="custom-card text-center h-100 ">
                    <div className="red-banner">
                      <h5>Expert Faculty</h5>
                    </div>
                    <Card.Body className="custom-card-body">
                      <Card.Text >
                        <div style={{ fontSize: '16px' }}>
                          Our faculty consists of experienced educators and CTET experts, providing in-depth knowledge and guidance.
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                {/* Search Field Card */}
                <Col md={3} >
                  <Card className="custom-card text-center  h-100">
                    <div className="red-banner">
                      <h5>Comprehensive Curriculum</h5>
                    </div>
                    <Card.Body className="custom-card-body">
                      <Card.Text>
                        <div style={{ fontSize: '16px' }}>
                          Our program covers the entire CTET syllabus, focusing on weak areas and ensuring thorough preparation.
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                {/* Pagination Card */}
                <Col md={3}>
                  <Card className="custom-card text-center  h-100">
                    <div className="red-banner">
                      <h5>Personalized Attention</h5>
                    </div>
                    <Card.Body className="custom-card-body">
                      <Card.Text>
                        <div style={{ fontSize: '16px' }}>
                          Small batch sizes ensure individual attention and guidance, helping students stay focused and motivated.
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                {/* Image Carousel Card */}
                <Col md={3}>
                  <Card className="custom-card text-center  h-100">
                    <div className="red-banner">
                      <h5>Success Rate</h5>
                    </div>
                    <Card.Body className="custom-card-body">
                      <Card.Text>
                        <div style={{ fontSize: '16px' }}>
                          Our students have achieved impressive results, making us the best CTET coaching in Delhi.
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </div>

            {/* Benefits of Choosing BESST for CTET Coaching */}

            <div fluid className=" mt-5 we-offer">
              <div className="offering_container">
                <div className="offering-heading">
                  <h2 className="h3_head text-start htwo_size">Benefits of Choosing BESST for CTET Coaching</h2>
                </div>

                <div className="row row-flex">
                  <div className="card-container">

                    <Card className="p-2 Ctet_Course_card match_height mb-3">
                      <Image
                        src={`${process.env.PUBLIC_URL}/assets/cardimg/4478ff9024b692b860004cb7aaa1f43f.png`}
                        alt="frame"
                        className="img-course mt-0"
                        fluid
                      />
                      <Card.Body>
                        <div className="p-2">
                          <h5
                            className="card-university-title"
                            style={{ color: "#71269C" }}
                          >
                            Structured Study Plan:
                          </h5>
                          <h5 className="card-university-title">
                            Our program helps you stay focused and on track, ensuring effective time management and preparation.
                          </h5>
                        </div>
                      </Card.Body>
                    </Card>

                    <Card className="p-2 Ctet_Course_card match_height mb-3">
                      <Image
                        src={`${process.env.PUBLIC_URL}/assets/cardimg/432d9280b2bd93e065bf1dcd14dfc11d.png`}
                        alt="frame"
                        className="img-course mt-0"
                        fluid
                      />
                      <Card.Body>
                        <div className="p-2">
                          <h5
                            className="card-university-title"
                            style={{ color: "#71269C" }}
                          >
                            Regular Assessments:
                          </h5>
                          <h5 className="card-university-title">
                            Mock tests and evaluations to gauge your progress, identify areas for improvement, and track growth.
                          </h5>
                        </div>
                      </Card.Body>
                    </Card>

                    <Card className="p-2 Ctet_Course_card match_height mb-3">
                      <Image
                        src={`${process.env.PUBLIC_URL}/assets/cardimg/c962f8f3520869897b37998bf3560539.png`}
                        alt="frame"
                        className="img-course mt-0"
                        fluid
                      />
                      <Card.Body>
                        <div className="p-2">
                          <h5
                            className="card-university-title"
                            style={{ color: "#71269C" }}
                          >
                            Doubt Clearing Sessions:
                          </h5>
                          <h5 className="card-university-title">Personalized support to clarify doubts and ensure thorough understanding of concepts.</h5>
                        </div>
                      </Card.Body>
                    </Card>

                  </div>
                </div>
              </div>
            </div>

            {/* Why BESST is the Best CTET Coaching in Delhi */}

            <div className="container mt-4">

              <Row className="gs-5 ge-5">
                {/* Breadcrumb Card */}
                <Col md={12} className='text-center mt-4 mb-4'>
                  <h2 style={{ color: '#5a1e7d', fontSize: '1.5em' }} className='htwo_size'>Why BESST is the Best CTET Coaching in Delhi</h2>
                </Col>

                <Col md={4} >
                  <Card className="custom-card text-center h-100 ">
                    <div className="red-banner">
                      <h5>Expert Faculty</h5>
                    </div>
                    <Card.Body className="custom-card-body">
                      <Card.Text >
                        <div style={{ fontSize: '16px' }}>
                          Our faculty has years of experience in teaching and guiding CTET aspirants.
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                {/* Search Field Card */}
                <Col md={4} >
                  <Card className="custom-card text-center  h-100">
                    <div className="red-banner">
                      <h5>Proven Track Record</h5>
                    </div>
                    <Card.Body className="custom-card-body">
                      <Card.Text>
                        <div style={{ fontSize: '16px' }}>

                          Our students have achieved impressive results, making us the top CTET coaching in Delhi
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                {/* Pagination Card */}
                <Col md={4}>
                  <Card className="custom-card text-center  h-100">
                    <div className="red-banner">
                      <h5>Personalized Attention</h5>
                    </div>
                    <Card.Body className="custom-card-body">
                      <Card.Text>
                        <div style={{ fontSize: '16px' }}>
                          We focus on individual attention, ensuring each student receives the support they need.
                        </div>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>

                {/* Image Carousel Card */}

              </Row>
            </div>

            {/* Frequently Asked Questions */}

            <div className='ctet_question mt-5'>
              <div className=''>
                <div className='text-center m-5 p-2'>
                  <h3 style={{ color: 'white' }} className='hthree_size'>Frequently Asked Questions</h3>
                </div>
                <div className="faq_head"></div>
                <Accordion className='ms-5 me-5 mb-5'>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header className="accordion_header">
                      Q. What is the duration of the CTET coaching program?
                    </Accordion.Header>
                    <Accordion.Body style={{ background: '#e3d4eb' }}>
                      A. The duration of the program is around 3 to 6 months.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="1">
                    <Accordion.Header className="accordion_header">
                      Q. Do you offer online coaching options?
                    </Accordion.Header>
                    <Accordion.Body style={{ background: '#e3d4eb' }}>
                      A. Yes, online classes are available.
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header className="accordion_header">
                      Q. What is the batch size for the program?
                    </Accordion.Header>
                    <Accordion.Body style={{ background: '#e3d4eb' }}>
                      A. The batch size encompasses around 10-15 students.
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header className="accordion_header">
                      Q. How do you support students with doubts and queries?
                    </Accordion.Header>
                    <Accordion.Body style={{ background: '#e3d4eb' }}>
                      A. Our faculty consists of experienced educators & CTET experts who are always there to support students with doubts & queries
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>

            {/* Course Curriculum and Structure */}

            <div className="ctet_syllabus container mt-5">
              <div className="align_center ml-2">
                <h2 style={{
                  fontSize: '1.5em', marginTop: '15px',
                }} className='text-center htwo_size'>Course Curriculum and Structure</h2>
                <div md={11} sm={11} xs={11}>
                  <div className="intro_ctet">
                    Our CTET coaching program is designed to cover the entire syllabus, focusing on key areas such as:
                  </div>
                  <Row className="intro-cards-container m-2">
                    <Col lg={3} md={6} sm={12} xs={12} className='p-3' >
                      <div className="intro_ctet_card p-2 " style={{ borderRadius: "15px" }}>
                        <Row className="intro-center">

                          <div className="col-md-3 text-center">
                            <Image
                              src={`${process.env.PUBLIC_URL}/assets/svg/students.png`}
                              alt="frame"

                              fluid
                            />
                          </div>

                          <div className="text-center col-md-9">
                            <p className="user_name">Child Development and Pedagogy</p>
                          </div>
                        </Row>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={12} xs={12} className='p-3' >
                      <div className="intro_ctet_card p-2 " style={{ borderRadius: "15px" }}>
                        <Row className="intro-center">

                          <div className="col-md-3 text-center">
                            <Image
                              src={`${process.env.PUBLIC_URL}/assets/svg/students.png`}
                              alt="frame"

                              fluid
                            />
                          </div>
                          <div className="text-center col-md-9">
                            <p className="user_name">Language I and II</p>
                          </div>
                        </Row>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={12} xs={12} className='p-3' >
                      <div className="intro_ctet_card p-2 " style={{ borderRadius: "15px" }}>
                        <Row className="intro-center">
                          <div className="col-md-3 text-center">
                            <Image
                              src={`${process.env.PUBLIC_URL}/assets/svg/students.png`}
                              alt="frame"

                              fluid
                            />
                          </div>
                          <div className="text-center col-md-9">
                            <p className="user_name">	Mathematics and Science</p>
                          </div>
                        </Row>
                      </div>
                    </Col>
                    <Col lg={3} md={6} sm={12} xs={12} className='p-3' >
                      <div className="intro_ctet_card p-2 " style={{ borderRadius: "15px" }}>
                        <Row className="intro-center">
                          <div className="col-md-3 text-center">
                            <Image
                              src={`${process.env.PUBLIC_URL}/assets/svg/students.png`}
                              alt="frame"

                              fluid
                            />
                          </div>
                          <div className="text-center col-md-9">
                            <p className="user_name">Social Studies and Environmental Studies</p>
                          </div>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>

            <FormModel show={show} setShow={setShow} />

          </div>
        </div>
      </div >
    </>
  )
}
