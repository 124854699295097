import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState, useEffect } from "react";
import { Card, Button, Form } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import { EncryptText } from "../../../Encrypt/CryptoEncryption";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";

import axios from "axios";

function Signin_OTP() {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const inputs = useRef([]);
  const [otpResent, setOtpResent] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [validationId, setValidationId] = useState("4");
  const [isShowWrongOTP, setIsShowWrongOTP] = useState(false);
  const [otpFailureMessage, setOtpFailureMessage] = useState("");
  const [seconds, setSeconds] = useState(30);
  const isSmallScreen = window.innerWidth <= 500;
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const navigate = useNavigate();
  var headers = new Headers();
  headers.append("Client_ID", "MVOZ7rblFHsvdzk25vsQpQ==");
  headers.append("Content-Type", "application/json");

  let timer;
  const handleTicker = () => {
    if (seconds > 0 && !otpResent) {
      timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
        // console.log(seconds)
      }, 1000);
    } else {

      clearInterval(timer);
      setOtpResent(true);
    }
  }

  const handleNext = async () => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    const phoneRegex = /^\d{10}$/;
    let name;
    if (validationId === "4") name = "EmailId";
    else if (validationId === "5") name = "Mobile";
    else if (validationId === "6") name = "WhatsappNo";
    if (step === 1) {
      if (mobile) {
        if (!phoneRegex.test(email)) {
          Swal.fire({
            icon: "error",
            title: "Please enter valid phone number",
            toast: true,
            position: "bottom-right",
            customClass: {
              popup: "colored-toast",
            },
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      } else if (!emailRegex.test(email)) {
        Swal.fire({
          icon: "error",
          title: "Please enter valid email",
          toast: true,
          position: "bottom-right",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        return;
      }
      try {
        const data = {
          email: email,
          [`${name}`]: EncryptText(email),
          OtpType: name == "Mobile" ? "4" : "4",
        };

        setButtonDisabled(true)
        // console.log(">>>>>>>>>>>>>>>", data);
        // setStep(2);
        const response = await axios.post(
          `${baseURL}/wl/sendOTP`,
          {
            [`${name}`]: EncryptText(email),
            OtpType: name == "Mobile" ? "4" : "4",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
            },
          }
        );

        if (response.data.ResultCode === "200") {
          // setStep(2);
          // console.log(12323);
          handleTicker();
          Swal.fire({
            icon: "success",
            title: "OTP Sent",
            toast: true,
            position: "bottom-right",
            customClass: {
              popup: "colored-toast",
            },
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          setStep(2);

        } else {
          // console.log(response.data);
          Swal.fire({
            icon: "error",
            title: response.data.ResultMessage,
            toast: true,
            position: "bottom-right",
            customClass: {
              popup: "colored-toast",
            },
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }

        setButtonDisabled(false)
      } catch (error) {
        // console.error("Error during email/OTP request", error);
        Swal.fire({
          icon: "success",
          title: "Error sending OTP",
          toast: true,
          position: "bottom-right",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } else if (step === 2) {
      try {
        // console.log({
        //   [`${name}`]: EncryptText(email),
        //   Otp: EncryptText(otp.join("")),
        // });
        const response = await axios.post(
          `${baseURL}/wl/verifyOTP`,
          {
            [`${name}`]: EncryptText(email),
            Otp: EncryptText(otp.join("")),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
            },
          }
        );

        if (response.data.status === 200) {
          Cookies.set("token", `Bearer ${response.data.result.token}`);
          Cookies.set("email", response.data.result.userLoginResBean.email);
          Cookies.set(
            "cuetPromoFlag",
            response.data.result.userLoginResBean.cuetPromoFlag
          );
          Cookies.set("userId", response.data.result.userLoginResBean.userId);
          Cookies.set("subscription_redirection", `yes`);
          navigate("/student-dashboard");
        } else {
          // console.log("Error:", response.data);
          // console.error("Failed to Verify OTP");
          // console.log(response.data)
          setIsShowWrongOTP(true);
          setOtpFailureMessage(response.data.ResultMessage);

        }
      } catch (error) {
        // console.error("Error during verification", error);
      }
    }
  };

  const whatsappOtp = async () => {
    setValidationId("6");
    try {
      const response = await axios.post(
        `${baseURL}/wl/sendOTP`,
        {
          WhatsappNo: EncryptText(email),
          Otp: validationId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
          },
        }
      );

      if (response.data.ResultCode === 200) {
        setStep(2);
      } else {
        // console.log("Error:", response.data);
        // console.error("Failed to send email/OTP");
      }
    } catch (error) {
      // console.error("Error during email/OTP request", error);
    }
  };

  const handleResendOtp = async () => {
    let name;
    if (validationId === "4") name = "EmailId";
    else if (validationId === "5") name = "Mobile";
    else if (validationId === "6") name = "WhatsappNo";

    try {
      setOtpResent(true);
      // console.log("sdakjsdkjandkjadkjandkj");
      setSeconds(30);
      handleTicker();
      const response = await axios.post(
        `${baseURL}/wl/sendOTP`,
        {
          [`${name}`]: EncryptText(email),
          OtpType: (name == "Mobile" ? "4" : "4"),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
          },
        }
      );

      if (response.data.ResultCode === "200") {
        setStep(2);
        Swal.fire({
          icon: "success",
          title: "OTP Sent",
          toast: true,
          position: "bottom-right",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error sending OTP",
          toast: true,
          position: "bottom-right",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error) {
      // console.error("Error during email/OTP request", error);
      Swal.fire({
        icon: "success",
        title: "Error sending OTP",
        toast: true,
        position: "bottom-right",
        customClass: {
          popup: "colored-toast",
        },
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    }
  };

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (index < inputs.current.length - 1 && value !== "") {
      inputs.current[index + 1].focus();
    }
  };

  return (
    <div
      style={{
        backgroundImage: `url("${process.env.PUBLIC_URL}/assets/cardimg/7a4c0d6ee7cceed8cf02a566d9596667.png")`,
        backgroundSize: "cover",
        height: "100vh",
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(123, 31, 162, 0.5)",
      }}
    >
      <div
        style={{
          backgroundSize: "cover",
          height: "100vh",
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(123, 31, 162, 0.5)",
        }}
      >
        {/* <button onClick={()=>{
        try {
          console.log(baseURL,EncryptText(email),validationId)
        } catch (error) {
          console.log(error)  
        }
      }} > click</button> */}

        <Card
          style={{ width: "700px", padding: "30px", height: "500px", backgroundColor: "#fff" }}
          className="cards-container"
        >
          <h4 className="sign_in_head text-center">{isSmallScreen ? "Sign In" : "Sign In to Your Account"}</h4>
          <div className="text-center mb-3">
            <div className="container">
              <div className="imagectn">
                <div className="circlespan">
                  {step >= 1 && (
                    <FaCheck
                      style={{
                        color: "white",
                        backgroundColor: "#71269C",
                        borderRadius: "50%",
                        height: "70%",
                        width: "70%",
                        padding: "8px",
                      }}
                    />
                  )}
                </div>
              </div>
              <span
                style={{
                  width: "200px",
                  border: "1px dashed #71269C",
                }}
              ></span>
              <div className="imagectn">
                <div className="circlespan">
                  {step >= 2 && (
                    <FaCheck
                      style={{
                        color: "white",
                        backgroundColor: "#71269C",
                        borderRadius: "50%",
                        height: "70%",
                        width: "70%",
                        padding: "8px",
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="container textctn" style={{ padding: "1px" }}>
              <p>Details</p>
              <p>Verify</p>
            </div>
          </div>

          <Form>
            {step === 1 && (
              <Form.Group className="mb-3">
                <Form.Label>{mobile ? "Mobile Number" : "Email"}</Form.Label>
                <Form.Control
                  type={mobile ? "number" : "text"}
                  placeholder={mobile ? "Enter Mobile Number" : "Enter Email"}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  style={{
                    height: "50px",
                    borderRadius: "0",
                  }}
                />
                {step === 1 && (
                  <div>
                    <div
                      onClick={() => {
                        setMobile((prev) => !prev);
                        setValidationId((prev) => (prev === "4" ? "5" : "4"));
                      }}
                      style={{
                        marginBlock: "5px",
                        cursor: "pointer",
                        color: "#7B1FA2",
                      }}
                    >
                      <p>Switch to {mobile ? "email" : "mobile"}</p>
                    </div>
                  </div>
                )}
              </Form.Group>
            )}

            {step === 2 && (
              <Form.Group className="mb-3 text-center">
                <Form.Label>Enter OTP</Form.Label>
                <div className="d-flex justify-content-center">
                  {[1, 2, 3, 4].map((_, index) => (
                    <input
                      className="otpsize"
                      key={index}
                      type="text"
                      maxLength="1"
                      style={{
                        width: "90px",
                        height: "60px",
                        marginRight: "10px",
                        textAlign: "center",
                        fontSize: "18px",
                        border: "2px solid #71269C",
                        borderRadius: "5px",
                      }}
                      value={otp[index]}
                      onChange={(e) => handleOtpChange(index, e.target.value)}
                      ref={(input) => {
                        inputs.current[index] = input; // Store the reference to the input field
                      }}
                    />
                  ))}
                </div>
                {isShowWrongOTP ? (<small className="text-danger">{otpFailureMessage}</small>) : undefined}
                <div className="mt-2">
                  <span className="mr-1">Did not receive OTP?</span>
                  <Button
                    variant="link"
                    onClick={handleResendOtp}
                    disabled={otpResent || seconds > 0}
                    // style={{ color: "#71269C" }}
                    className="custom-button"
                  >
                    {otpResent ? "OTP Resent" : "Resend"}
                  </Button>
                  <div style={{ color: "#71269C", fontSize: "12px" }}>
                    {seconds > 0 ? seconds : undefined}
                  </div>
                </div>
              </Form.Group>
            )}

            <div
              className="d-flex justify-content-center mt-3"
              style={{ gap: "4px" }}
            >
              <Button
                variant="secondary"
                size="lg"
                style={{
                  width: "48%",
                  background: step === 1 ? "#fff" : "#7B1FA2",
                  color: step === 1 ? "rgb(0, 0, 0)" : "#FFF",
                  opacity: step === 1 ? 0.8 : 1,
                }}
                disabled={buttonDisabled}
                onClick={handleNext}
              >
                {step === 1 ? "Get OTP" : "Sign In"}
              </Button>
            </div>
          </Form>

          <div className="span_login_msg mt-3 text-center">
            <span className="span_msg">
              Remember your password?{" "}
              <Link to="/signin">
                <span className="span_login">Sign In</span>
              </Link>
            </span>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default Signin_OTP;
