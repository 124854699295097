// Forgot_Password.js
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import { Card, Button, Form } from "react-bootstrap";
import { FaGoogle, FaFacebook, FaApple, FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import { EncryptText } from "../../../Encrypt/CryptoEncryption";
import Swal from "sweetalert2";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";

function Forgot_Password() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const inputs = useRef([]);
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [otpResent, setOtpResent] = useState(false);
  const [otpFailureMessage, setOtpFailureMessage] = useState("");
  const [mobile, setMobile] = useState(false);
  const [validationId, setValidationId] = useState("4");
  const [isShowWrongOTP, setIsShowWrongOTP] = useState(false);

  var headers = new Headers();
  headers.append("Client_ID", "MVOZ7rblFHsvdzk25vsQpQ==");
  headers.append("Content-Type", "application/json");
  const navigate = useNavigate();

  const handleNext = async () => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    const phoneRegex = /^\d{10}$/;
    let name;
    if (validationId === "4") name = "EmailId";
    else if (validationId === "5") name = "Mobile";
    else if (validationId === "6") name = "WhatsappNo";
    if (step === 1) {
      if (mobile) {
        if (!phoneRegex.test(email)) {
          Swal.fire({
            icon: "error",
            title: "Please enter valid phone number",
            toast: true,
            position: "bottom-right",
            customClass: {
              popup: "colored-toast",
            },
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      } else if (!emailRegex.test(email)) {
        Swal.fire({
          icon: "error",
          title: "Please enter valid email",
          toast: true,
          position: "bottom-right",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        return;
      }
      try {
        const response = await axios.post(
          `${baseUrl}/wl/sendOTP`,
          {
            [`${name}`]: EncryptText(email),
            // OtpType: validationId,
            OtpType: name == "Mobile" ? "4" : "4",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
            },
          }
        );

        // console.log(response);
        if (response.data.ResultCode === "200") {
          setStep(2);
          Swal.fire({
            icon: "success",
            title: "OTP Sent",
            toast: true,
            position: "bottom-right",
            customClass: {
              popup: "colored-toast",
            },
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        } else {
          // console.log(response.data);
          Swal.fire({
            icon: "error",
            title: "Error sending OTP",
            toast: true,
            position: "bottom-right",
            customClass: {
              popup: "colored-toast",
            },
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      } catch (error) {
        // console.error("Error during email/OTP request", error);
        Swal.fire({
          icon: "success",
          title: "Error sending OTP",
          toast: true,
          position: "bottom-right",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } else if (step === 2) {
      try {
        // console.log({
        //   [`${name}`]: EncryptText(email),
        //   Otp: EncryptText(otp.join("")),
        // });
        const response = await axios.post(
          `${baseUrl}/wl/verifyOTP`,
          {
            [`${name}`]: EncryptText(email),
            Otp: EncryptText(otp.join("")),
          },
          {
            headers: {
              "Content-Type": "application/json",
              Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
            },
          }
        );

        if (response.data.status === 200) {
          Cookies.set("token", `Bearer ${response.data.result.token}`);
          Cookies.set("email", response.data.result.userLoginResBean.email);
          Cookies.set(
            "cuetPromoFlag",
            response.data.result.userLoginResBean.cuetPromoFlag
          );
          Cookies.set("userId", response.data.result.userLoginResBean.userId);
          Cookies.set("subscription_redirection", `yes`);
          // Cookies.set(
          //   "preferredCourseId",
          //   response.data.result.userLoginResBean.preferredCourseId
          // );
          // localStorage.setItem(
          //   "preferredCourseId",
          //   response.data.result.userLoginResBean.preferredCourseId
          // );
          navigate("/student-dashboard");
        } else {
          setIsShowWrongOTP(true);
          setOtpFailureMessage(response.data.ResultMessage);
          // console.log("Error:", response.data);
          // console.error("Failed to Verify OTP");
        }
      } catch (error) {
        // console.error("Error during verification", error);
      }
    }
  };

  const whatsappOtp = async () => {
    setValidationId("6");
    try {
      const response = await axios.post(
        `${baseUrl}/wl/sendOTP`,
        {
          WhatsappNo: EncryptText(email),
          Otp: validationId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
          },
        }
      );

      if (response.data.ResultCode === 200) {
        setStep(2);
      } else {
        // console.log("Error:", response.data);
        // console.error("Failed to send email/OTP");
      }
    } catch (error) {
      // console.error("Error during email/OTP request", error);
    }
  };

  const handleResendOtp = async () => {
    let name;
    if (validationId === "4") name = "EmailId";
    else if (validationId === "5") name = "Mobile";
    else if (validationId === "6") name = "WhatsappNo";

    try {
      setOtpResent(true);
      const response = await axios.post(
        `${baseUrl}/wl/sendOTP`,
        {
          [`${name}`]: EncryptText(email),
          OtpType: validationId,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Client_ID: "MVOZ7rblFHsvdzk25vsQpQ==",
          },
        }
      );

      if (response.data.ResultCode === "200") {
        setStep(2);
        Swal.fire({
          icon: "success",
          title: "OTP Sent",
          toast: true,
          position: "bottom-right",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Error sending OTP",
          toast: true,
          position: "bottom-right",
          customClass: {
            popup: "colored-toast",
          },
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    } catch (error) {
      // console.error("Error during email/OTP request", error);
      Swal.fire({
        icon: "success",
        title: "Error sending OTP",
        toast: true,
        position: "bottom-right",
        customClass: {
          popup: "colored-toast",
        },
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    }
  };

  const handleOtpChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    if (index < inputs.current.length - 1 && value !== "") {
      inputs.current[index + 1].focus();
    }
  };
  return (
    <div
      style={{
        backgroundImage: `url("${process.env.PUBLIC_URL}/assets/cardimg/7a4c0d6ee7cceed8cf02a566d9596667.png")`,
        backgroundSize: "cover",
        height: "100vh",
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(123, 31, 162, 0.5)",
      }}
    >
      <div
        style={{
          backgroundSize: "cover",
          height: "100vh",
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(123, 31, 162, 0.5)",
        }}
      >
        <Card
          style={{ width: "700px", padding: "30px", height: "500px", backgroundColor: "#fff" }}
          className="cards-container"
        >
          <h4
            className="Forgot_Password_head text-center"
            style={{ fontWeight: "bold" }}
          >
            {step === 1 ? "Forgot Password" : "Please Enter OTP"}
          </h4>

          {/* <div className="text-center mb-3">
          {Array.from({ length: 2 }).map((_, index) => (
            <React.Fragment key={index}>
              {index > 0 && (
                <span
                  style={{
                    height: "2px",
                    width: "30px",
                    background: step > index ? "#71269C" : "gray",
                    display: "inline-block",
                    margin: "0 5px",
                  }}
                />
              )}
              <span
                style={{
                  borderRadius: "50%",
                  width: "30px",
                  height: "30px",
                  background: step > index ? "#71269C" : "white",
                  border: `2px solid ${step > index ? "#71269C" : "gray"}`,
                  color: step > index ? "#71269C" : "gray",
                  display: "inline-block",
                  textAlign: "center",
                  lineHeight: "30px",
                  fontSize: "16px",
                }}
              >
                {step > index + 1 && <FaCheck style={{ color: "white" }} />}
              </span>
            </React.Fragment>
          ))}
        </div> */}

          <Form>
            {step === 1 && (
              <Form.Group className="mb-3">
                <Form.Label>{mobile ? "Mobile Number" : "Email"}</Form.Label>
                <Form.Control
                  type={mobile ? "number" : "text"}
                  placeholder={mobile ? "Enter Mobile Number" : "Enter Email"}
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  style={{
                    height: "50px",
                    borderRadius: "0",
                  }}
                />
                {step === 1 && (
                  <div>
                    <div
                      onClick={() => {
                        setMobile((prev) => !prev);
                        setValidationId((prev) => (prev === "4" ? "5" : "4"));
                      }}
                      style={{
                        marginBlock: "5px",
                        cursor: "pointer",
                        color: "#7B1FA2",
                      }}
                    >
                      <p>Switch to {mobile ? "email" : "mobile"}</p>
                    </div>
                  </div>
                )}
              </Form.Group>
            )}

            {step === 2 && (
              <Form.Group className="mb-3 text-center">
                <Form.Label>Enter OTP</Form.Label>
                <div className="d-flex justify-content-center">
                  {[1, 2, 3, 4].map((_, index) => (
                    <input
                      className="otpsize"
                      key={index}
                      type="text"
                      maxLength="1"
                      style={{
                        width: "90px",
                        height: "60px",
                        marginRight: "10px",
                        textAlign: "center",
                        fontSize: "18px",
                        border: "2px solid #71269C",
                        borderRadius: "5px",
                      }}
                      value={otp[index]}
                      onChange={(e) => handleOtpChange(index, e.target.value)}
                      ref={(input) => {
                        inputs.current[index] = input; // Store the reference to the input field
                      }}
                    />
                  ))}
                </div>
                {isShowWrongOTP ? (<small className="text-danger">{otpFailureMessage}</small>) : undefined}
                <div className="mt-2">
                  <Button
                    variant="link"
                    onClick={handleResendOtp}
                    disabled={otpResent}
                    style={{ color: "#71269C" }}
                  >
                    {otpResent ? "OTP Resent" : "Did not receive OTP? Resend"}
                  </Button>
                </div>
              </Form.Group>
            )}

            <div
              className="d-flex justify-content-center mt-3"
              style={{ gap: "4px" }}
            >
              <Button
                variant="secondary"
                size="lg"
                style={{
                  width: "48%",
                  background: step === 1 ? "#fff" : "#7B1FA2",
                  color: step === 1 ? "rgb(0, 0, 0)" : "#FFF",
                  opacity: step === 1 ? 0.8 : 1,
                }}
                onClick={handleNext}
              >
                {step === 1 ? "Get OTP" : "Sign In"}
              </Button>
              {/* {mobile && step === 1 && (
                <Button
                  variant="secondary"
                  size="lg"
                  style={{
                    backgroundColor: "#25D366",
                    color: "white",
                  }}
                  onClick={whatsappOtp}
                >
                  {step === 1 ? "Get OTP on WhatsApp" : "Sign In"}
                </Button>
              )} */}
            </div>
          </Form>

          <div className="span_login_msg mt-3 text-center">
            <span className="span_msg">
              Remember your password?{" "}
              <Link to="/signin">
                <span className="span_login">Sign In</span>
              </Link>
            </span>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default Forgot_Password;
